<template>
  <div class="item d-flex justify-content-between align-items-center" v-bind:class="active ? ' selectedItem' : ''">
    <div class="svgWrapper d-flex align-items-center" @click="$emit('showVector')">
      <!-- <VectorAltliklariSvg width="1.5rem" /> -->
      <v-icon class="me-2" :name="icon" scale="1"></v-icon>
      <div class="user-select-none">{{ title ?? "Adsız Katman" }}</div>
    </div>
    <div class="buttons-group">
      <ShareButton style="cursor: pointer" @click="openSharedVectorScreen" />
      <EditButton style="cursor: pointer" />
      <DeleteButton style="cursor: pointer" @click="$emit('deleteVector')" />
    </div>
  </div>
</template>

<script>
import VectorAltliklariSvg from "@/assets/icons/VectorAltliklariSvg.vue"
import ShareButton from "./ShareButton.vue"
import EditButton from "./EditButton.vue"
import DeleteButton from "./DeleteButton.vue"

export default {
  props: {
    showSharedVectorScreen: Function,
    icon: String,
    title: String,
    active: Boolean,
  },
  components: {
    VectorAltliklariSvg,
    ShareButton,
    EditButton,
    DeleteButton,
  },
  methods: {
    openSharedVectorScreen() {
      this.$emit("shareVector")
      this.showSharedVectorScreen(true)
    },
  },
}
</script>

<style scoped>
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  color: #a4a4a4;
  padding: 0.7rem 1rem;
  margin: 10px 0px;
}

.selectedItem {
  background-color: #75ff6899 !important;
}

.buttons-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 3800px) {
  .item {
    font-size: 1.5rem;
    padding: 1.5rem 1.8rem;
    margin: 20px 0px;
  }
  .svgWrapper svg {
    width: 2rem !important;
  }
}
</style>
