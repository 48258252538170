<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 38 38"
  >
    <path
      id="zoom-out-svgrepo-com"
      d="M19.889,7.222A12.667,12.667,0,1,0,32.556,19.889,12.667,12.667,0,0,0,19.889,7.222ZM3,19.889A16.889,16.889,0,1,1,33.231,30.245L40.382,37.4A2.111,2.111,0,1,1,37.4,40.382l-7.151-7.151A16.89,16.89,0,0,1,3,19.889Zm8.444,0a2.111,2.111,0,0,1,2.111-2.111H26.222a2.111,2.111,0,0,1,0,4.222H13.556A2.111,2.111,0,0,1,11.444,19.889Z"
      transform="translate(-3 -3)"
      fill="#3c4043"
      fill-rule="evenodd"
    />
  </svg>
</template>


<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>