<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" viewBox="0 0 50.6 44">
    <path
      id="history-svgrepo-com"
      d="M11.024,23.064l2.82-2.82a2.2,2.2,0,1,1,3.111,3.111l-6.6,6.6a2.2,2.2,0,0,1-3.111,0l-6.6-6.6a2.2,2.2,0,1,1,3.111-3.111l2.862,2.862A22,22,0,1,1,11.49,37.83a2.2,2.2,0,0,1,3.42-2.768,17.6,17.6,0,1,0-3.886-12ZM28.6,8.6a2.2,2.2,0,0,1,2.2,2.2V23.089l5.956,5.956a2.2,2.2,0,1,1-3.111,3.111l-6.6-6.6A2.2,2.2,0,0,1,26.4,24V10.8A2.2,2.2,0,0,1,28.6,8.6Z"
      transform="translate(0 -2)"
      fill="#3c4043"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>