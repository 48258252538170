<template>
  <Transition name="modal">
    <keep-alive>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="modal-mask" @click="$emit('close')" />
        <div class="modal-container">
          <div class="modal-header">
            <div class="close" @click="$emit('close')">X</div>
            <div name="header" class="header user-select-none">Kullanıcı Girişi</div>
          </div>
          <div class="modal-body">
            <div name="body">
              <form @submit.prevent="login">
                <PhoneInput :telNumber="form.telNumber" @onInputFocus="onInputFocus" @onInputChange="onInputChange" />
                <PasswordInput :password="form.password" @onInputFocus="onInputFocus" @onInputChange="onInputChange" />
                <div class="d-flex align-self-start mt-3 user-select-none">
                  <input
                    id="keepItOpen"
                    class="checkBox"
                    type="checkbox"
                    checked
                    :value="keepItOpen"
                    @input="keepItOpenChange"
                  />
                  <label for="keepItOpen" class="ms-2">Bu cihazda oturumumu açık tut</label>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <button class="accountLogin mt-3">
                    <LoginSvg height="1.1rem" class="me-1" />
                    Oturum Aç
                  </button>
                </div>
              </form>
              <button class="virtualKeyboard mt-3" @click="openKeyboard">
                <VirtualKeyboardSvg height="1rem" class="me-1" />
                Sanal Klavye Kullan
              </button>
            </div>
          </div>
        </div>
        <SimpleKeyboard
          v-if="keyboardVisible"
          @onChange="onChange"
          @onKeyPress="onKeyPress"
          :input="form[inputName]"
          :inputName="inputName"
        />
      </div>
    </keep-alive>
  </Transition>
</template>

<script>
import PhoneInput from "./PhoneInput.vue"
import PasswordInput from "./PasswordInput.vue"
import LoginSvg from "../../../assets/icons/LoginSvg.vue"
import VirtualKeyboardSvg from "../../../assets/icons/VirtualKeyboardSvg.vue"
import SimpleKeyboard from "./SimpleKeyboard"
import axios from "axios"
import { useToast } from "vue-toastification"

export default {
  components: {
    PhoneInput,
    PasswordInput,
    LoginSvg,
    VirtualKeyboardSvg,
    SimpleKeyboard,
  },
  data() {
    return {
      form: {
        telNumber: "",
        password: "",
      },
      inputName: "telNumber",
      keepItOpen: true,
      keyboardVisible: false,
    }
  },
  emits: ["closeModal"],
  methods: {
    keepItOpenChange() {
      this.keepItOpen = !this.keepItOpen
    },
    openKeyboard(value) {
      if (value) {
        this.keyboardVisible = value
      } else {
        this.keyboardVisible = !this.keyboardVisible
      }
    },
    onChange(input) {
      this.form[this.inputName] = input
    },
    onKeyPress(button) {
    },
    onInputChange(input) {
      this.form[input.target.id] = input.target.value
    },
    onInputFocus(input) {
      this.inputName = input.target.id
    },
    login() {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/auth/login", {
          phone_number: this.form.telNumber,
          password: this.form.password,
        })
        .then((res) => {
          if (res.status === 200 && res.data.logged) {
            const { token, name, surname, phone, identification_number, management, authorization_level } =
              res.data.data
            this.$store.dispatch("setLoggedData", {
              token: token,
              name: name,
              surname: surname,
              phone: phone,
              identification_number: identification_number,
              management: management,
              authorization_level: authorization_level,
            })

            this.$store.dispatch("setArventoData")
            this.$emit("closeModal")
            const toast = useToast()
            toast.success("Giriş Başarılı.", {
              timeout: 2000,
              hideProgressBar: true,
            })

            if (this.keepItOpen) {
              localStorage.setItem(
                "data",
                JSON.stringify({
                  token: token,
                  name: name,
                  surname: surname,
                  phone: phone,
                  identification_number: identification_number,
                  management: management,
                  authorization_level: authorization_level,
                })
              )
            }
          }
        })
        .catch((err) => {
          const res = err.response
          if (res.status === 400) {
            if (res.data.type === "USERNAME_AND_PASSWORD_INCORRECT") {
              alert("Kullanıcı adı ve şifre hatalı")
            }
            if (res.data.type === "ValidationError") {
              alert(res.data.message)
            }
          }
        })
    },
  },
}
</script>

<style scoped>
.close {
  position: absolute;
  top: -14px;
  right: -14px;
  cursor: pointer;
  padding: 0.11rem 0.5rem;
  border-radius: 100%;
  font-size: 15px;
  color: #fff;
  background-color: #205c42;
}

.header {
  font-size: 18px;
  border-bottom: 2px solid #205c42;
}

.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container {
  position: relative;
  width: 375px;
  margin: 0px auto;
  z-index: 100;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  display: flex;
  margin: 20px 0;
}

/* The following styles are auto-applied to elements with
 transition="modal" when their visibility is toggled
 by Vue.js.

 You can easily play with the modal transition by editing
 these styles. */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.checkBox {
  accent-color: #205c42;
  width: 1rem;
  padding: 0.3rem;
}

.accountLogin {
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  border: none;
  background-color: #205c42;
  color: #fff;
  border-radius: 8px;
  text-align: center;
}

.accountLogin:hover {
  background-color: #1e6748;
}

.virtualKeyboard {
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  border: none;
  background-color: #ececec;
  color: #000;
  border-radius: 8px;
  text-align: center;
  user-select: none;
}

.simple-keyboard {
  max-width: 850px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.simple-keyboard:focus {
  box-shadow: none;
  border-color: #dee2e6;
  border-right: 0;
}

@media screen and (min-width: 3800px) {
  .modal-container {
    width: 575px;
  }
  .simple-keyboard {
    max-width: 850px;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
