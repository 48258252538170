<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 33.349 29.921"
  >
    <g
      id="camera-svgrepo-com_1_"
      data-name="camera-svgrepo-com (1)"
      transform="translate(-1.75 -2.75)"
    >
      <path
        id="Path_279"
        data-name="Path 279"
        d="M14.142,20.283A5.142,5.142,0,1,0,9,15.142,5.141,5.141,0,0,0,14.142,20.283Z"
        transform="translate(4.283 4.283)"
        fill="none"
        :stroke="selectedFilter === 'Kamera' ? '#fff' : '#17be33'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.5"
      />
      <path
        id="Path_280"
        data-name="Path 280"
        d="M3,25.937V12.912c0-1.92,0-2.88.374-3.613a3.427,3.427,0,0,1,1.5-1.5c.733-.374,1.693-.374,3.613-.374h1.807a3.847,3.847,0,0,0,.413-.011,1.714,1.714,0,0,0,1.241-.767,3.849,3.849,0,0,0,.195-.365,7.7,7.7,0,0,1,.39-.729,3.428,3.428,0,0,1,2.481-1.534A7.7,7.7,0,0,1,15.838,4h5.173a7.7,7.7,0,0,1,.826.022,3.428,3.428,0,0,1,2.481,1.534,7.738,7.738,0,0,1,.39.729,3.809,3.809,0,0,0,.195.365,1.714,1.714,0,0,0,1.241.767,3.851,3.851,0,0,0,.413.011h1.807c1.92,0,2.88,0,3.613.374a3.427,3.427,0,0,1,1.5,1.5c.374.733.374,1.693.374,3.613V25.937c0,1.92,0,2.88-.374,3.613a3.427,3.427,0,0,1-1.5,1.5c-.733.374-1.693.374-3.613.374H8.484c-1.92,0-2.88,0-3.613-.374a3.427,3.427,0,0,1-1.5-1.5C3,28.817,3,27.857,3,25.937Z"
        fill="none"
        :stroke="selectedFilter === 'Kamera' ? '#fff' : '#17be33'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>