<template>
  <ol-tile-layer v-if="$store.state.buyuksehir_imar_1000.selected">
    <ol-source-wmts
      :url="$store.state.buyuksehir_imar_1000.url"
      :matrixSet="matrixSet"
      :format="format"
      :layer="layerName"
      :styles="styleName"
    ></ol-source-wmts>
  </ol-tile-layer>
</template>

<script>
import { ref } from "vue";

export default {
  data() {
    return {
      layerName: ref("1"),
      matrixSet: ref("3857"),
      format: ref("image/png"),
      styleName: ref("default"),
    };
  },
};
</script>