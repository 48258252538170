<template>
  <div v-if="this.$store.state.auth.logged" class="d-flex flex-column">
    <!-- <Transition name="slide-fade">
      <div class="d-flex flex-column buttonscontainer" v-if="userKatmanlar">
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
        <button
          type="button"
          class="user-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <UserKatmanSvg width="2rem" />
          Sizin
          <span>Katmanlarınız</span>
        </button>
      </div>
    </Transition> -->
    <button
      @click="openUserKatmanlar"
      type="button"
      class="user-katmanlar d-flex flex-column align-items-center justify-content-between btn btn-light"
    >
      <UserKatmanButtonSvg width="2rem" />
      Benim
      <span>Katmanlarım</span>
    </button>
  </div>
</template>

<script>
import UserKatmanButtonSvg from '../../../assets/icons/UserKatmanButtonSvg.vue'
// import UserKatmanSvg from '../../../assets/icons/UserKatmanSvg.vue'

export default {
  props: {
    userKatmanlar: Boolean,
    openUserKatmanlar: Function,
  },
  components: {
    UserKatmanButtonSvg,
    // UserKatmanSvg,
  },
}
</script>

<style scoped>
.buttonscontainer {
  max-height: 28.9rem;
  overflow-y: scroll;
  overflow-x: none;
}

.buttonscontainer::-webkit-scrollbar {
  display: none;
  scroll-padding-right: -100px;
}

.user-katmanlar {
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #c82b2b;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
}

.user-katmanlar:hover {
  border-bottom-color: #c82b2b;
}

.user-katmanlar-test {
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #c82b2b;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
}

.user-katmanlar-test svg {
  width: 1rem;
}

.user-katmanlar-test:hover {
  border-bottom-color: #c82b2b;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(580px);
  opacity: 0;
}

@media only screen and (min-width: 3800px) {
  .buttonscontainer {
    max-height: 39rem;
    overflow-y: scroll;
    overflow-x: none;
  }
  .user-katmanlar {
    padding: 1rem 2rem;
  }
  .user-katmanlar svg {
    width: 2.95rem;
  }

  .user-katmanlar-test {
    padding: 1rem 2rem;
  }
  .user-katmanlar-test svg {
    width: 1.86rem;
  }
}
</style>
