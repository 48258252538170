<template>
  <svg
    id="packages-package-svgrepo-com"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 31.656 31.656"
  >
    <g id="Group_74" data-name="Group 74" transform="translate(0 17.377)">
      <g id="Group_73" data-name="Group 73">
        <path
          id="Path_118"
          data-name="Path 118"
          d="M12.864,275.2H1.347A1.351,1.351,0,0,0,0,276.547v11.585a1.351,1.351,0,0,0,1.347,1.347H12.864a1.339,1.339,0,0,0,1.347-1.28V276.547A1.351,1.351,0,0,0,12.864,275.2Zm-1.347,11.652H2.627v-9.025h3.1v2.559a1.347,1.347,0,1,0,2.694,0v-2.559h3.1Z"
          transform="translate(0 -275.2)"
          :fill="selectedFilter === 'Envanter' ? '#fff' : '#6330db'"
        />
      </g>
    </g>
    <g id="Group_76" data-name="Group 76" transform="translate(17.445 17.377)">
      <g id="Group_75" data-name="Group 75">
        <path
          id="Path_119"
          data-name="Path 119"
          d="M289.131,275.2H277.614a1.351,1.351,0,0,0-1.347,1.347v11.585a1.351,1.351,0,0,0,1.347,1.347h11.517a1.339,1.339,0,0,0,1.347-1.28V276.547A1.351,1.351,0,0,0,289.131,275.2Zm-1.28,11.652h-8.891v-9.025h3.1v2.559a1.351,1.351,0,0,0,1.347,1.347,1.4,1.4,0,0,0,1.347-1.347v-2.559h3.1Z"
          transform="translate(-276.267 -275.2)"
          :fill="selectedFilter === 'Envanter' ? '#fff' : '#6330db'"
        />
      </g>
    </g>
    <g id="Group_78" data-name="Group 78" transform="translate(8.689)">
      <g id="Group_77" data-name="Group 77">
        <path
          id="Path_120"
          data-name="Path 120"
          d="M150.464,0H138.947A1.351,1.351,0,0,0,137.6,1.347V12.932a1.351,1.351,0,0,0,1.347,1.347h11.585a1.279,1.279,0,0,0,1.28-1.28V1.347A1.351,1.351,0,0,0,150.464,0Zm-1.28,11.652h-8.891V2.627h3.1v2.9a1.348,1.348,0,0,0,2.694.067V2.627h3.1Z"
          transform="translate(-137.6)"
          :fill="selectedFilter === 'Envanter' ? '#fff' : '#6330db'"
        />
      </g>
    </g>
  </svg>
</template>


<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>