<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 13.761 25.028"
  >
    <g id="Page-1" transform="translate(13.761 0) rotate(90)">
      <g
        id="Dribbble-Light-Preview"
        transform="translate(25.028 13.761) rotate(180)"
      >
        <g id="icons" transform="translate(0 0)">
          <path
            id="arrow_up-_337_"
            data-name="arrow_up-[#337]"
            d="M24.663,13.3h0a1.326,1.326,0,0,0,0-1.827L14.321.76a2.438,2.438,0,0,0-3.538,0L.365,11.548a1.341,1.341,0,0,0-.013,1.826,1.236,1.236,0,0,0,1.783.013L11.668,3.5a1.231,1.231,0,0,1,1.77,0l9.457,9.8a1.207,1.207,0,0,0,1.768,0"
            fill="#3c4043"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>