const getModule = {
  state: {
    new_vector: [],
    vector: null,
  },
  mutations: {
    setLayerData(state, data) {
      state.vector = data
    },
    newLayerData(state, data) {
      state.new_vector.push(data)
    },
    clearLayerData(state) {
      state.vector = null
    },
    newClearLayerData(state) {
      state.new_vector = null
    },
  },
  actions: {
    newLayerData({ commit }, data) {
      commit("newLayerData", data)
    },
    newClearLayerData({ commit }) {
      commit("newClearLayerData")
    },
    setLayerData({ commit }, data) {
      commit("setLayerData", data)
    },
    clearLayerData({ commit }) {
      commit("clearLayerData")
    },
  },
  getters: {
    vector: (state) => state.vector,
  },
}

export default getModule
