<template>
  <ol-animated-clusterlayer :animationDuration="500" :distance="50">
    <ol-source-vector>
      <ol-feature
        v-for="project in this.$store.state.investment.data"
        :key="project.id"
      >
        <ol-geom-point
          :coordinates="[
            parseFloat(project.Longitude),
            parseFloat(project.Latitude),
          ]"
        ></ol-geom-point>
      </ol-feature>
    </ol-source-vector>

    <ol-style :overrideStyleFunction="overrideStyleFunction">
      <ol-style-stroke color="red" :width="5"></ol-style-stroke>
      <ol-style-fill color="rgba(255,255,255,0.1)"></ol-style-fill>

      <ol-style-circle :radius="20">
        <ol-style-stroke
          color="black"
          :width="18"
          :lineDash="[]"
          lineCap="butt"
        ></ol-style-stroke>
        <ol-style-fill color="black"></ol-style-fill>
      </ol-style-circle>

      <ol-style-text>
        <ol-style-fill color="white"></ol-style-fill>
      </ol-style-text>
    </ol-style>
  </ol-animated-clusterlayer>
</template>

<script>
import markerIcon from '@/assets/png/marker.png'

export default {
  props: {},
  emits: {},
  created() {
    this.$store.dispatch('setInvestmentData')
  },
  data() {
    return {
      markerIcon: markerIcon,
    }
  },
  methods: {
    overrideStyleFunction(feature, style) {
      const clusteredFeatures = feature.get('features')
      const size = clusteredFeatures.length

      const getWidth = window.screen.width

      const color = size > 9 ? '192,0,0' : size >= 5 ? '255,128,0' : '0,128,0'
      let radius
      if (getWidth < 3800) {
        radius = Math.max(8, Math.min(size, 20))
      } else if (getWidth > 3810) {
        radius = Math.max(17, Math.min(size, 41))
        style.getText().setFont('18px solid')
      }

      const dash = (2 * Math.PI * radius) / 6
      const calculatedDash = [0, dash, dash, dash, dash, dash, dash]

      style.getImage().getStroke().setLineDash(dash)
      style
        .getImage()
        .getStroke()
        .setColor('rgba(' + color + ',0.5)')
      style.getImage().getStroke().setLineDash(calculatedDash)
      style
        .getImage()
        .getFill()
        .setColor('rgba(' + color + ',1)')

      style.getImage().setRadius(radius)

      style.getText().setText(size.toString())
    },
  },
}
</script>

<style scoped></style>
