<template>
  <svg
    id="Dribbble-Light-Preview"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 22.439 21.371"
  >
    <g id="icons">
      <path
        id="edit_cover-_1481_"
        data-name="edit_cover-[#1481]"
        d="M385.439,209.91v10.461H363V199.45h11.22v2.092h-8.976v16.737H383.2V209.91Zm-14.586.325,9-8.408,1.744,2.135-9.107,9.087h-1.637Zm-2.244,4.906h4.843l11.115-11.177L379.915,199,368.61,209.352Z"
        transform="translate(-363 -199)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>