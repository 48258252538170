<template>
  <div class="position-relative">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="showProfile ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: showProfile ? 'visible' : 'hidden',
        width: showProfile ? '20%' : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <div class="closeCanvas" @click="showProfileFunction2">
          <ArrowLeftSvg width="0.5rem" />
        </div>
        <div class="title user-select-none">Kullanıcı Profiliniz</div>
        <div class="inputs">
          <ProfileInput
            :placeholder="this.$store.state.auth.name"
            name="userName"
            type="text"
            label="Adınız"
            :disabled="true"
          >
            <UserProfileNameSvg width="0.9rem" />
            <template v-slot:lock>
              <LockSvg width="0.9rem" />
            </template>
          </ProfileInput>
          <ProfileInput
            :placeholder="this.$store.state.auth.surname"
            name="userSurname"
            type="text"
            label="Soyadınız"
            :disabled="true"
          >
            <UserProfileSurnameSvg width="1rem" />
            <template v-slot:lock>
              <LockSvg width="0.9rem" />
            </template>
          </ProfileInput>
          <ProfileInput
            :value="inputs.newPhoneNumber"
            name="newPhoneNumber"
            type="tel"
            :placeholder="this.$store.state.auth.phone"
            label="Telefon Numaranız"
            :disabled="false"
            @onInputFocus="onInputFocus"
            @onInputChange="onInputChange"
          >
            <CallSvg width="0.9rem" />
          </ProfileInput>
          <ProfileInput
            :placeholder="this.$store.state.auth.identification_number"
            name="TC"
            type="number"
            label="T.C. Kimlik No"
            :disabled="true"
          >
            <UserProfileTCSvg width="1.1rem" />
            <template v-slot:lock>
              <LockSvg width="0.9rem" />
            </template>
          </ProfileInput>
          <ProfileInput
            :placeholder="this.$store.state.auth.management"
            name="mudurluk"
            type="text"
            label="Müdürlük"
            :disabled="true"
          >
            <UserProfileMudurlukSvg width="0.9rem" />
            <template v-slot:lock>
              <LockSvg width="0.9rem" />
            </template>
          </ProfileInput>
          <ProfileInput
            :placeholder="this.$store.state.auth.authorization_level"
            name="yetki"
            type="text"
            label="Yetki Seviyesi"
            :disabled="true"
          >
            <UserProfileYetkiSvg width="0.9rem" />
            <template v-slot:lock>
              <LockSvg width="0.9rem" />
            </template>
          </ProfileInput>
        </div>
        <button
          class="block"
          :disabled="saveDisable"
          @click="test"
          :style="{
            borderColor: saveDisable ? '#a4a4a4' : ' #2aa83f',
            color: saveDisable ? '#a4a4a4' : '#2aa83f',
          }"
        >
          <CheckSvg
            width="0.9rem"
            :fill="saveDisable ? '#a4a4a4' : '#2aa83f'"
          />Kaydet
        </button>
        <button class="virtualKeyboard mt-3" @click="openKeyboard">
          <VirtualKeyboardSvg height="1rem" />
          Sanal Klavye Kullan
        </button>
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
// import FooterLogoWhiteSvg from "@/assets/icons/FooterLogoWhiteSvg.vue";
import ArrowLeftSvg from "@/assets/icons/ArrowLeftSvg.vue";
import UserProfileNameSvg from "@/assets/icons/UserProfileNameSvg.vue";
import UserProfileSurnameSvg from "@/assets/icons/UserProfileSurnameSvg.vue";
import CallSvg from "@/assets/icons/CallSvg.vue";
import UserProfileTCSvg from "@/assets/icons/UserProfileTCSvg.vue";
import UserProfileMudurlukSvg from "@/assets/icons/UserProfileMudurlukSvg.vue";
import UserProfileYetkiSvg from "@/assets/icons/UserProfileYetkiSvg.vue";
import LockSvg from "@/assets/icons/LockSvg.vue";
import CheckSvg from "@/assets/icons/CheckSvg.vue";
import VirtualKeyboardSvg from "@/assets/icons/VirtualKeyboardSvg.vue";
import ProfileInput from "./ProfileInput.vue";

export default {
  props: {
    showProfile: Boolean,
    showProfileFunction: Function,
    openKeyboard: Function,
    inputs: Object,
    saveDisable: Boolean,
  },

  components: {
    // FooterLogoWhiteSvg,
    ArrowLeftSvg,
    ProfileInput,
    UserProfileNameSvg,
    UserProfileSurnameSvg,
    CallSvg,
    UserProfileTCSvg,
    UserProfileYetkiSvg,
    LockSvg,
    UserProfileMudurlukSvg,
    CheckSvg,
    VirtualKeyboardSvg,
  },

  methods: {
    showProfileFunction2() {
      this.showProfileFunction(false);
      this.openKeyboard("close");
    },
    onInputChange(input) {
      this.$emit("onInputChange", input);
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input);
    },
  },
};
</script>

<style scoped>
.offcanvas {
  padding-top: 2rem;
}
.footer-logo {
  position: absolute;
  bottom: 30px;
  align-self: center;
}
.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}
.title {
  font-size: 1.2rem;
  text-decoration: 1px #205c42 solid underline;
  text-underline-offset: 3px;
  margin: 0 0 0 1rem;
}
.inputs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 1rem 0 1rem;
}
.block {
  border: 1px solid;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.7rem 7.15rem 0.7rem 7.15rem;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  margin: 2rem 1rem 0 1rem !important;
}
.block svg {
  width: 1.1rem;
  margin-right: 1rem;
  padding-bottom: 0.3rem;
}
.virtualKeyboard {
  display: block;
  margin-left: 1rem;
  width: 90%;
  padding: 0.8rem 0;
  border: none;
  background-color: #ececec;
  color: #000;
  border-radius: 8px;
  text-align: center;
  user-select: none;
}
.simple-keyboard {
  max-width: 250px;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: 0;
  color: #000;
  z-index: 100;
}

.simple-keyboard:focus {
  box-shadow: none;
  border-color: #dee2e6;
  border-right: 0;
}
@media screen and (min-width: 3800px) {
  .footer-logo {
    bottom: 30px;
  }
  .offcanvas {
    padding-top: 1.9rem;
  }
  .footer-logo svg {
    height: 4.8rem;
  }
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }
  .title {
    font-size: 2.1rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
    margin: 0 0 -1rem 1rem;
  }
  .block {
    display: block;
    /* width: 100%; */
    border: 1px solid;
    background-color: #fff;
    border-radius: 5px;
    padding: 1.2rem 17.5rem 1.2rem 17.5rem;
    font-size: 30px;
    cursor: pointer;
    text-align: center;
    margin: 4rem 1rem 0 1rem !important;
  }
  .block svg {
    width: 1.8rem;
    margin-right: 1rem;
    padding-bottom: 0.3rem;
  }

  .virtualKeyboard {
    width: 95%;
    padding: 1.2rem 0;
    font-size: 30px;
    z-index: 999;
  }
  .simple-keyboard {
    max-width: 350px;
    margin-top: 25rem;
    margin-left: auto;
    margin-right: 0;
  }
}
</style>
