<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 43.116 26.147"
  >
    <g id="number-list-svgrepo-com" transform="translate(0.058 -6.316)">
      <path
        id="Path_210"
        data-name="Path 210"
        d="M10.345,17.69H38.588a1.345,1.345,0,0,0,0-2.69H10.345a1.345,1.345,0,0,0,0,2.69Z"
        transform="translate(3.124 2.995)"
        fill="#a4a4a4"
      />
      <path
        id="Path_211"
        data-name="Path 211"
        d="M10.345,10.69H38.588a1.345,1.345,0,0,0,0-2.69H10.345a1.345,1.345,0,0,0,0,2.69Z"
        transform="translate(3.124 0.581)"
        fill="#a4a4a4"
      />
      <path
        id="Path_212"
        data-name="Path 212"
        d="M39.933,23.345A1.345,1.345,0,0,0,38.588,22H10.345a1.345,1.345,0,0,0,0,2.69H38.588A1.345,1.345,0,0,0,39.933,23.345Z"
        transform="translate(3.124 5.41)"
        fill="#a4a4a4"
      />
      <path
        id="Path_213"
        data-name="Path 213"
        d="M1.365,9.926v2.69a1.345,1.345,0,1,0,2.69,0V7.68A1.345,1.345,0,0,0,3.342,6.5,1.345,1.345,0,0,0,2,6.5L.652,7.4a1.345,1.345,0,0,0,.713,2.528Z"
        transform="translate(0 0)"
        fill="#a4a4a4"
      />
      <path
        id="Path_214"
        data-name="Path 214"
        d="M1.345,16.951a1.251,1.251,0,0,0,.7-.2A15.117,15.117,0,0,1,.39,18.686a1.353,1.353,0,0,0,.955,2.3h2.69A1.349,1.349,0,0,0,4.25,18.3a5.272,5.272,0,0,0,1.13-2.69,2.475,2.475,0,0,0-2.69-2.354A2.475,2.475,0,0,0,0,15.606a1.345,1.345,0,0,0,1.345,1.345Z"
        transform="translate(0.02 2.389)"
        fill="#a4a4a4"
      />
      <path
        id="Path_215"
        data-name="Path 215"
        d="M0,25.3A2.475,2.475,0,0,0,2.69,27.65,2.475,2.475,0,0,0,5.38,25.3a2.69,2.69,0,0,0-.336-1.345,2.69,2.69,0,0,0,.336-1.345,2.475,2.475,0,0,0-2.69-2.354A2.475,2.475,0,0,0,0,22.606a1.345,1.345,0,0,0,1.345,1.345A1.345,1.345,0,0,0,0,25.3Z"
        transform="translate(0.02 4.804)"
        fill="#a4a4a4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>