<template>
  <div
    class="general-tools mb-4 row d-flex flex-row align-items-end justify-content-between"
    :style="{
      // width:
      //   showProject ||
      //   showVehicle ||
      //   showUser ||
      //   showProfile ||
      //   showVectorCoasters ||
      //   vectorCoasterNew
      //     ? '80%'
      //     : '100%',
      transition: 'width 0.3s',
    }"
  >
    <GeneralToolsButtons
      :showCluster="showCluster"
      :showClusterFunction="showClusterFunction"
      :showProjectFunction="showProjectFunction"
      :changeSelectedScreen="changeSelectedScreen"
      :getMahaleler="getMahaleler"
      :showVectorCoasters="showVectorCoasters"
      :showVectorCoastersFunction="showVectorCoastersFunction"
      :showProfileFunction="showProfileFunction"
    />
    <!-- <NotificationsMap
      v-if="this.$store.state.auth.logged"
      :showProject="showProject"
    /> -->
  </div>
</template>

<script>
import GeneralToolsButtons from "./GeneralToolsButtons.vue";
// import NotificationsMap from "./NotificationsMap.vue";

export default {
  props: {
    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,
    showProfile: Boolean,
    showVectorCoasters: Boolean,
    showCluster: Boolean,
    showClusterFunction: Function,
    showProjectFunction: Function,
    changeSelectedScreen: Function,
    getMahaleler: Function,
    showVectorCoastersFunction: Function,
    showProfileFunction: Function,
    vectorCoasterNew: Boolean,
  },
  components: {
    GeneralToolsButtons,
    // NotificationsMap,
  },
};
</script>


<style scoped>
.general-tools {
  position: absolute;
  /* width: 100%; */
  bottom: 0;
  left: 0;
}
</style>