<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 58.858 61.546"
  >
    <g id="exclamation-mark-svgrepo-com" transform="translate(-11.181 0)">
      <path
        id="Path_272"
        data-name="Path 272"
        d="M67.482,31.946a3.279,3.279,0,0,1,0-2.347l2.34-6.144a3.293,3.293,0,0,0-1.278-3.932L63.04,15.928a3.3,3.3,0,0,1-1.379-1.9L59.942,7.682A3.3,3.3,0,0,0,56.6,5.251l-6.568.328A3.3,3.3,0,0,1,47.8,4.854L42.678.73a3.294,3.294,0,0,0-4.136,0l-5.12,4.124a3.3,3.3,0,0,1-2.232.726l-6.567-.328a3.3,3.3,0,0,0-3.345,2.431L19.56,14.029a3.3,3.3,0,0,1-1.38,1.9l-5.505,3.595A3.3,3.3,0,0,0,11.4,23.455L13.739,29.6a3.288,3.288,0,0,1,0,2.347L11.4,38.091a3.3,3.3,0,0,0,1.278,3.932l5.505,3.6a3.288,3.288,0,0,1,1.38,1.9l1.718,6.347a3.3,3.3,0,0,0,3.345,2.431l6.567-.327a3.291,3.291,0,0,1,2.232.724l5.12,4.125a3.3,3.3,0,0,0,4.135,0L47.8,56.692a3.291,3.291,0,0,1,2.232-.724l6.568.327a3.3,3.3,0,0,0,3.345-2.431l1.718-6.347a3.282,3.282,0,0,1,1.379-1.9l5.505-3.6a3.293,3.293,0,0,0,1.277-3.932ZM44.55,44.372a2.277,2.277,0,0,1-2.275,2.277h-3.33a2.278,2.278,0,0,1-2.276-2.277V40.9a2.278,2.278,0,0,1,2.276-2.277h3.33A2.277,2.277,0,0,1,44.55,40.9Zm0-12.042a2.276,2.276,0,0,1-2.275,2.275h-3.33a2.276,2.276,0,0,1-2.276-2.275V17.175A2.277,2.277,0,0,1,38.945,14.9h3.33a2.276,2.276,0,0,1,2.275,2.277Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>