<template>
  <div v-if="vectorCoasterItems">
    <div class="position-relative">
      <div
        class="offcanvas offcanvas-end border-0"
        :class="showVectorCoasters ? 'show' : ''"
        tabindex="-1"
        :style="{
          visibility: showVectorCoasters ? 'visible' : 'hidden',
          width: showVectorCoasters ? '20%' : '0%',
        }"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="container">
          <div class="closeCanvas" @click="showVectorCoastersFunction(false)">
            <ArrowLeftSvg width="0.5rem" />
          </div>
          <div class="row p-3">
            <div class="col-6 d-flex justify-content-start align-items-center">
              <div class="title user-select-none">Vektör Katmanlarınız</div>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center user-select-none">
              <button
                type="button"
                class="toggleNewVector d-flex flex-row align-items-center justify-content-between"
                @click="toggleNewVectorMenuFunction"
              >
                <YeniAltlikOlusturSvg width="1.3rem" />
                Yeni Katman Oluştur
              </button>
            </div>
            <div class="d-flex mt-4 gap-2 vectorButtonsWrapper">
              <VectorCoastersButton
                class="btnClickStyle"
                @click="changeVectorMenuFunction"
                :style="{
                  backgroundColor: vectorMenu === 'created' ? '#205c42' : '#fff',
                  color: vectorMenu === 'created' ? '#fff' : '#205c42',
                  border: vectorMenu === 'created' && '1px solid #205C42',
                }"
              >
                <OlusturdugunuzAltliklarSvg width="1.5rem" :fill="vectorMenu === 'created' ? '#fff' : '#205c42'" />
                <div style="text-align: left; margin-left: 15px" class="textWrapper d-flex flex-column">
                  Oluşturduğunuz
                  <span> Katmanlar </span>
                </div>
              </VectorCoastersButton>
              <VectorCoastersButton
                class="btnClickStyle2"
                @click="changeVectorMenuFunction"
                :style="{
                  backgroundColor: vectorMenu === 'shared' ? '#205c42' : '#fff',
                  color: vectorMenu === 'shared' ? '#fff' : '#205c42',
                  border: vectorMenu === 'created' && '1px solid #205C42',
                }"
              >
                <PaylasSvg width="1.5rem" :fill="vectorMenu === 'shared' ? '#fff' : '#205c42'" />
                <div style="text-align: left; margin-left: 15px" class="textWrapper d-flex flex-column">
                  Sizinle Paylaşılan
                  <span> Katmanlar </span>
                </div>
              </VectorCoastersButton>
            </div>
            <div class="mt-2" v-if="vectorMenu === 'created'">
              <div v-for="vectorItem in this.$store.state.layers.vectors" v-bind:key="vectorItem._id">
                <VectorCoastersItem
                  :active="this.$store.state.layer.vector?._id == vectorItem._id"
                  @showVector="showVectorAnimation(vectorItem._id)"
                  @deleteVector="deleteVector(vectorItem._id)"
                  @shareVector="shareVector(vectorItem._id)"
                  :showSharedVectorScreen="showSharedVectorScreen"
                  :title="vectorItem.name"
                  :icon="vectorItem.icon"
                />
              </div>
            </div>
            <div class="mt-2" v-else>
              <template v-for="(item, index) in this.$store.state?.shared_layers?.shared_layers" :key="index">
                <VectorSharedItem
                  @showVector="showSharedVectorAnimation(item?.layer?._id)"
                  :title="item?.layer?.name"
                  :name="item?.layer?.created_user?.name + ' ' + item?.layer?.created_user?.surname"
                  :management="item?.layer?.created_user?.management?.name"
                  :end_date="item?.end_date"
                  :is_end_date="item?.is_end_date"
                />
              </template>
            </div>
          </div>
        </div>

        <!-- <div class="footer-logo">
          <FooterLogoWhiteSvg height="2.25rem" />
        </div> -->
      </div>
    </div>
  </div>
  <div v-if="vectorCoasterNew">
    <VectorCoasterNewItem
      :vectorCoasterNew="vectorCoasterNew"
      @btnClose="toggleNewVectorMenuFunction"
      :openKeyboard="openKeyboard"
      @onInputChange="onInputChange"
      @onInputFocus="onInputFocus"
      :inputs="inputs"
    />
  </div>
</template>

<script>
// import FooterLogoWhiteSvg from "@/assets/icons/FooterLogoWhiteSvg.vue";
import ArrowLeftSvg from "@/assets/icons/ArrowLeftSvg.vue"
import YeniAltlikOlusturSvg from "@/assets/icons/YeniAltlikOlusturSvg.vue"
import OlusturdugunuzAltliklarSvg from "@/assets/icons/OlusturdugunuzAltliklarSvg.vue"
import PaylasSvg from "@/assets/icons/PaylasSvg.vue"
import VectorCoastersButton from "./VectorCoastersButton.vue"
import VectorCoastersItem from "./VectorCoastersItem.vue"
import VectorSharedItem from "./VectorSharedItem.vue"
import VectorCoasterNewItem from "./VectorCoasterNewItem/VectorCoasterNewItem.vue"
import axios from "axios"

export default {
  created() {
    this.init()
  },
  data() {
    return {
      vectorMenu: "created",
      vectors: [],
    }
  },
  emits: ["onInputChange", "onInputFocus"],
  props: {
    showVectorCoasters: Boolean,
    showVectorCoastersFunction: Function,
    sharedVectorScreen: Boolean,
    showSharedVectorScreen: Function,

    openKeyboard: Function,
    inputs: Object,
    vectorCoasterItems: Boolean,
    vectorCoasterNew: Boolean,
    toggleNewVectorMenuFunction: Function,
  },

  components: {
    // FooterLogoWhiteSvg,
    ArrowLeftSvg,
    YeniAltlikOlusturSvg,
    VectorCoastersButton,
    VectorCoastersItem,
    VectorSharedItem,
    OlusturdugunuzAltliklarSvg,
    PaylasSvg,
    VectorCoasterNewItem,
  },
  methods: {
    changeVectorMenuFunction() {
      if (this.vectorMenu === "created") {
        this.vectorMenu = "shared"
      } else {
        this.vectorMenu = "created"
      }
    },
    onInputChange(input) {
      this.$emit("onInputChange", input)
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input)
    },
    deleteVector(id) {
      axios
        .delete(process.env.VUE_APP_BACKEND_URL + "/layer/data/" + id, {
          headers: {
            Authorization: this.$store.state.auth.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            location.reload()
          }
        })
    },
    shareVector(id) {
      const item = this.$store.state.layers.vectors.find((i) => i._id == id)
      const sharedUsers = item?.shared_users
      this.$store.dispatch("setSharedUsersData", sharedUsers)
    },
    showLayerView(item) {
      this.$store.dispatch("setLayerData", item)
      let lat, long
      if (item?.data[0]?.type == "circle") {
        lat = item?.data[0]?.data?.center[1]
        long = item?.data[0]?.data?.center[0]
      } else if (item?.data[0]?.type == "line") {
        lat = item?.data[0]?.data?.coordinates[0][1]
        long = item?.data[0]?.data?.coordinates[0][0]
      } else if (item?.data[0]?.type == "polygon") {
        lat = item?.data[0]?.data?.coordinates[0][0][1]
        long = item?.data[0]?.data?.coordinates[0][0][0]
      }
      this.$mapView.animate({ center: [long, lat] }, { zoom: 10 })
    },
    showVectorAnimation(id) {
      const item = this.$store.state.layers.vectors.find((i) => i._id == id)
      this.showLayerView(item)
    },
    showSharedVectorAnimation(id) {
      const item = this.$store.state.shared_layers.shared_layers.find((i) => i.layer._id == id)
      console.log("item.layer: ", item.layer)
      this.showLayerView(item.layer)
    },
    init() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "/layer/data", {
          headers: {
            Authorization: this.$store.state.auth.token,
          },
        })
        .then((res) => {
          this.vectors = res.data.data
          this.$store.dispatch("setLayersData", res.data.data)
          this.$store.dispatch("setSharedLayersData", res.data.shared_users_data)
        })
    },
  },
}
</script>

<style scoped>
.offcanvas {
  padding-top: 2rem;
}
.footer-logo {
  position: absolute;
  bottom: 30px;
  align-self: center;
}
.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}
.title {
  font-size: 1.1rem;
  text-decoration: 1px #205c42 solid underline;
  text-underline-offset: 3px;
}
.toggleNewVector {
  background-color: #2da026;
  font-size: 0.75rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.btnClickStyle {
  margin-right: 0.116rem;
}
.btnClickStyle svg {
  margin-right: -0.3rem;
}
.btnClickStyle2 {
  margin-right: 0.116rem;
}
.btnClickStyle2 svg {
  margin-right: -0.3rem;
}

@media screen and (min-width: 3800px) {
  .footer-logo {
    bottom: 30px;
  }
  .offcanvas {
    padding-top: 1.9rem;
  }
  .footer-logo svg {
    height: 4.8rem;
  }
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }
  .title {
    font-size: 2.1rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
    margin: 0 0 -1rem 0;
  }
  .toggleNewVector {
    padding: 1rem !important;
    font-size: 1.5rem !important;
  }

  .toggleNewVector svg {
    width: 3rem;
  }

  .btnClickStyle {
    padding: 1rem !important;
    font-size: 1.5rem !important;
  }
  .btnClickStyle2 {
    margin-left: 1rem;
    padding: 1rem !important;
    font-size: 1.5rem !important;
  }
  .textWrapper {
    line-height: 2rem;
    margin-left: 2rem !important;
  }

  .btnClickStyle svg {
    width: 3rem;
  }

  .btnClickStyle2 svg {
    width: 3rem;
  }
  .vectorButtonsWrapper {
    margin-bottom: 1.5rem;
  }
}
</style>
