import * as Vue from "vue"
import App from "./App.vue"
import axios from "axios"
import VueAxios from "vue-axios"

import store from "./store"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"

import OpenLayersMap from "vue3-openlayers"
import "vue3-openlayers/dist/vue3-openlayers.css"

import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"

import { OhVueIcon, addIcons } from "oh-vue-icons"
import * as IoIcons from "oh-vue-icons/icons/io"

const IoIconsPack = Object.values({ ...IoIcons })
addIcons(...IoIconsPack)

const app = Vue.createApp(App)

app.use(VueAxios, axios)
app.use(OpenLayersMap)
app.use(Toast)
app.use(store)

app.component("v-icon", OhVueIcon)

app.mount("#app")

export { app }
