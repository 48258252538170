<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 47.995 35.98"
  >
    <g id="car-with-gps-svgrepo-com" transform="translate(0 -32.307)">
      <path
        id="Path_58"
        data-name="Path 58"
        d="M46.285,39.683a2.086,2.086,0,0,0-.552.077l-2.1.578-2.178-5.305a4.753,4.753,0,0,0-4.066-2.726H17.01a4.753,4.753,0,0,0-4.066,2.726l-2.175,5.3L8.7,39.761a2.086,2.086,0,0,0-.552-.077,1.7,1.7,0,0,0-1.71,1.8v1.236c0,.051,0,.1.008.15A13.86,13.86,0,0,0,4.03,44.8a13.761,13.761,0,1,0,19.461,19.46,13.7,13.7,0,0,0,3.3-5.293H39.61v2.632A2.211,2.211,0,0,0,41.818,63.8H44.7a2.211,2.211,0,0,0,2.209-2.209V51.055a16.566,16.566,0,0,0-1.04-5.27l-.352-.856h.267A2.211,2.211,0,0,0,48,42.72V41.484A1.7,1.7,0,0,0,46.285,39.683ZM15.648,35.7a2.856,2.856,0,0,1,2.438-1.635H36.317A2.856,2.856,0,0,1,38.754,35.7l3.295,8.025a1.1,1.1,0,0,1-1.1,1.635H24.027c-.173-.193-.351-.384-.535-.569a13.671,13.671,0,0,0-9.731-4.031c-.064,0-.128,0-.192,0ZM13.761,64.168a9.642,9.642,0,1,1,6.818-2.824A9.579,9.579,0,0,1,13.761,64.168ZM42.66,53.88a.886.886,0,0,1-.883.883H35.519a.886.886,0,0,1-.883-.883v-3a.886.886,0,0,1,.883-.883h6.258a.886.886,0,0,1,.883.883v3Z"
        transform="translate(0 0)"
        :fill="selectedFilter === 'Araç' ? '#fff' : '#CB2121'"
      />
      <path
        id="Path_59"
        data-name="Path 59"
        d="M34.573,138.652H32.987a.451.451,0,0,0-.451.451v.373a.451.451,0,0,0,.451.451h.235a.271.271,0,0,1,.27.27v.746c0,.135-.038.142-.119.156a2.872,2.872,0,0,1-.481.035,1.928,1.928,0,0,1,.1-3.854,3.615,3.615,0,0,1,1.073.142.424.424,0,0,0,.549-.292l.132-.5a.366.366,0,0,0-.255-.481,4.924,4.924,0,0,0-1.481-.2c-2.2,0-3.62,1.305-3.629,3.325a3.067,3.067,0,0,0,.9,2.318,3.534,3.534,0,0,0,2.581.862,7.066,7.066,0,0,0,1.88-.286.358.358,0,0,0,.278-.393V139.1A.452.452,0,0,0,34.573,138.652Z"
        transform="translate(-23.92 -84.363)"
        :fill="selectedFilter === 'Araç' ? '#fff' : '#CB2121'"
      />
      <path
        id="Path_60"
        data-name="Path 60"
        d="M68.579,136.555a3.056,3.056,0,0,0-1.95-.516,12.79,12.79,0,0,0-1.664.094.336.336,0,0,0-.319.36v5.518a.451.451,0,0,0,.451.451h.666a.451.451,0,0,0,.451-.451v-1.624c0-.092.031-.118.139-.118h.041c.066,0,.136,0,.209,0a2.964,2.964,0,0,0,2.075-.677,2.083,2.083,0,0,0,.6-1.536A1.9,1.9,0,0,0,68.579,136.555Zm-1.987,2.462c-.092,0-.173,0-.245-.007-.056,0-.133-.008-.133-.2V137.6a.335.335,0,0,1,.213-.326H66.7c.638,0,1,.3,1,.82C67.706,138.673,67.29,139.017,66.592,139.017Z"
        transform="translate(-52.625 -84.443)"
        :fill="selectedFilter === 'Araç' ? '#fff' : '#CB2121'"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M95.2,138.368c-.818-.311-1.09-.469-1.09-.789s.318-.508.811-.508a2.612,2.612,0,0,1,.928.146.406.406,0,0,0,.55-.265l.153-.554a.33.33,0,0,0-.23-.434,4.062,4.062,0,0,0-1.374-.223c-1.456,0-2.434.786-2.434,1.956,0,.864.593,1.507,1.76,1.914.732.263.979.462.979.789,0,.369-.326.581-.893.581a3.432,3.432,0,0,1-1.251-.257.407.407,0,0,0-.149-.031c-.088,0-.245.036-.319.283l-.122.494a.5.5,0,0,0,.31.579,4.847,4.847,0,0,0,1.447.26c1.912,0,2.59-1.084,2.59-2.011C96.863,139.4,96.333,138.788,95.2,138.368Z"
        transform="translate(-75.301 -84.201)"
        :fill="selectedFilter === 'Araç' ? '#fff' : '#CB2121'"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>