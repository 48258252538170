<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 47.176 47.176"
  >
    <g
      id="map-svgrepo-com_1_"
      data-name="map-svgrepo-com (1)"
      transform="translate(-2 -2)"
    >
      <path
        id="create-dashboard-svgrepo-com"
        d="M21.346,29.209H5.621A2.621,2.621,0,0,0,3,31.83V47.555a2.621,2.621,0,0,0,2.621,2.621H21.346a2.621,2.621,0,0,0,2.621-2.621V31.83A2.621,2.621,0,0,0,21.346,29.209ZM18.725,44.934H8.242V34.451H18.725ZM47.555,3H31.83a2.621,2.621,0,0,0-2.621,2.621V21.346a2.621,2.621,0,0,0,2.621,2.621H47.555a2.621,2.621,0,0,0,2.621-2.621V5.621A2.621,2.621,0,0,0,47.555,3ZM44.934,18.725H34.451V8.242H44.934Zm2.621,18.346H42.313V31.83a2.621,2.621,0,1,0-5.242,0v5.242H31.83a2.621,2.621,0,1,0,0,5.242h5.242v5.242a2.621,2.621,0,1,0,5.242,0V42.313h5.242a2.621,2.621,0,0,0,0-5.242ZM21.346,3H5.621A2.621,2.621,0,0,0,3,5.621V21.346a2.621,2.621,0,0,0,2.621,2.621H21.346a2.621,2.621,0,0,0,2.621-2.621V5.621A2.621,2.621,0,0,0,21.346,3ZM18.725,18.725H8.242V8.242H18.725Z"
        transform="translate(-1 -1)"
        :fill="fill"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    fill: String,
  },
};
</script>