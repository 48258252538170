<template>
  <div
    class="map-tools d-flex flex-column align-items-end justify-content-center z-3"
    :style="{
      marginRight: '12px',
      right:
        showProject || showVehicle || showUser || showProfile || showVectorCoasters || vectorCoasterNew
          ? screenSize
          : 0,
      transition: 'right 0.3s',
    }"
  >
    <div v-if="this.$store.state.auth.logged" class="d-flex flex-column">
      <button
        v-if="zones?.length !== 0"
        type="button"
        class="btn btn-light d-flex align-items-center justify-content-center"
        @click="toggleNewVectorMenuFunction"
      >
        <v-icon name="io-save-outline" scale="1.15" />
      </button>
      <button v-if="zones?.length !== 0" type="button" class="btn btn-light mb-4" @click="deleteFeature">
        <v-icon name="io-trash-outline" scale="1.15" />
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="drawTypeChange('Circle')"
        :style="{ backgroundColor: drawType === 'Circle' ? '#205c42' : '#fff' }"
      >
        <CircleVectorSvg width="1.2rem" height="1.2rem" :fill="drawType === 'Circle' ? '#fff' : '#3c4043'" />
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="drawTypeChange('LineString')"
        :style="{
          backgroundColor: drawType === 'LineString' ? '#205c42' : '#fff',
        }"
      >
        <SquareVectorSvg width="1.2rem" height="1.2rem" :fill="drawType === 'LineString' ? '#fff' : '#3c4043'" />
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="drawTypeChange('Polygon')"
        :style="{
          backgroundColor: drawType === 'Polygon' ? '#205c42' : '#fff',
        }"
      >
        <DrawAreaSvg width="1.2rem" height="1.2rem" :fill="drawType === 'Polygon' ? '#fff' : '#3c4043'" />
      </button>
    </div>
    <button type="button" class="btn btn-light">
      <HandSvg width="1.2rem" height="1.2rem" />
    </button>
    <button type="button" class="btn btn-light" @click="zoomIn">
      <ZoomInSvg width="1.2rem" height="1.2rem" />
    </button>
    <button type="button" class="btn btn-light" @click="zoomOut">
      <ZoomOutSvg width="1.2rem" height="1.2rem" />
    </button>
  </div>
</template>

<script>
import CircleVectorSvg from "@/assets/icons/CircleVectorSvg.vue"
import SquareVectorSvg from "@/assets/icons/SquareVectorSvg.vue"
import DrawAreaSvg from "@/assets/icons/DrawAreaSvg.vue"
import HandSvg from "@/assets/icons/HandSvg.vue"
import ZoomInSvg from "@/assets/icons/ZoomInSvg.vue"
import ZoomOutSvg from "@/assets/icons/ZoomOutSvg.vue"
import DeleteBlackSvg from "@/assets/icons/DeleteBlackSvg.vue"
import DownloadSvg from "@/assets/icons/DownloadSvg.vue"

export default {
  props: {
    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,
    showProfile: Boolean,
    showVectorCoasters: Boolean,
    zoomOut: Function,
    zoomIn: Function,
    drawTypeChange: Function,
    drawType: String,
    zones: Array,
    deleteFeature: Function,
    toggleNewVectorMenuFunction: Function,
    vectorCoasterNew: Boolean,
  },
  components: {
    CircleVectorSvg,
    SquareVectorSvg,
    DrawAreaSvg,
    HandSvg,
    ZoomInSvg,
    DeleteBlackSvg,
    ZoomOutSvg,
    DownloadSvg,
  },
  data() {
    return {
      screenSize: null,
    }
  },
  mounted() {
    this.getScreen()
  },
  methods: {
    getScreen() {
      const getWidth = window.screen.width
      if (getWidth > 3800) {
        this.screenSize = "48rem"
      } else if (getWidth > 1800) {
        this.screenSize = "24rem"
      } else if (getWidth > 1600) {
        this.screenSize = "21rem"
      } else {
        this.screenSize = "18rem"
      }
    },
  },
}
</script>

<style scoped>
.map-tools {
  position: absolute;
  bottom: 24px;
  margin-top: 5rem;
}

.bi {
  transform: scale(2.5);
}

button {
  margin-bottom: 0.45rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
@media only screen and (min-width: 3800px) {
  .map-tools {
    bottom: 150px;
  }
  button {
    margin-top: 0.5rem;
    margin-right: 2rem;
    padding: 1.5rem;
  }
  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
}
</style>
