import axios from "axios"

export const getDeviceId = () => {
  let navigator_info = window.navigator
  let screen_info = window.screen
  let uid = navigator_info.mimeTypes.length
  uid += navigator_info.userAgent.replace(/\D+/g, "")
  uid += navigator_info.plugins.length
  uid += screen_info.height || ""
  uid += screen_info.width || ""
  uid += screen_info.pixelDepth || ""
  return uid
}

export const dateFormat = (date) => {
  const today = new Date(date)
  const yyyy = today.getFullYear()
  let mm = today.getMonth() + 1 // Months start at 0!
  let dd = today.getDate()

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  const formattedToday = dd + "." + mm + "." + yyyy
  return formattedToday
}

export const capitalizeSentence = (str) => {
  const newStr = str
    ?.split(" ")
    ?.map((item) => item[0]?.toUpperCase() + item?.slice(1).toLowerCase())
    .join(" ")

  return newStr
}

export const axiosInstance = () => {
  // Axios instance oluşturma
  const axiosInstanceSet = axios.create()

  // Varsayılan catch fonksiyonunu tanımlama
  axiosInstanceSet.interceptors.response.use(
    (response) => response,
    (error) => {
      // Hata durumunda yapılacak işlemler
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 401) {
          console.error("Oturum sonlandırılıyor, hata kodu:", error.response.status)
          localStorage.removeItem("data")
          window.location.href = "/"
        }
      }
      // Hata iletisini tekrar fırlatabilirsiniz
      return Promise.reject(error)
    }
  )
  return axiosInstanceSet
}

export const decodeEntities = (encodedString) => {
  let translate_re = /&(nbsp|amp|quot|lt|gt);/g
  let translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">",
  }
  return encodedString
    ?.replace(translate_re, (_, entity) => {
      return translate[entity]
    })
    ?.replace(/&#(\d+);/gi, (_, numStr) => {
      let num = parseInt(numStr, 10)
      return String.fromCharCode(num)
    })
}
