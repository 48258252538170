<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 38 38"
    class="mb-1 me-1"
  >
    <g
      id="user-svgrepo-com_5_"
      data-name="user-svgrepo-com (5)"
      transform="translate(-6.499 -6.472)"
    >
      <path
        id="Path_5"
        data-name="Path 5"
        d="M6,13.616A11.449,11.449,0,0,1,17.274,2,11.449,11.449,0,0,1,28.547,13.616,11.449,11.449,0,0,1,17.274,25.232,11.449,11.449,0,0,1,6,13.616Z"
        transform="translate(8.227 4.472)"
        fill="#474b4e"
        fill-rule="evenodd"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M8.517,17.747C11.607,16.428,15.729,16,21.01,16s9.385.427,12.473,1.739a10.911,10.911,0,0,1,6.432,7.28,1.988,1.988,0,0,1-1.867,2.6H3.96A1.979,1.979,0,0,1,2.1,25.032,10.909,10.909,0,0,1,8.517,17.747Z"
        transform="translate(4.49 16.856)"
        fill="#474b4e"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>