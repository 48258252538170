<template>
  <ol-vector-layer>
    <ol-source-vector
      :style="{ zIndex: 9999 }"
      v-if="this.$store.state.arvento.show && this.$store.state.arvento.data"
    >
      <ol-feature
        v-for="item in this.$store.state.arvento.data"
        :key="item.Node"
      >
        <ol-geom-point
          :coordinates="[item.LongitudeX, item.LatitudeY]"
        ></ol-geom-point>
        <!-- [ "OTOMOBIL", "KAMYON", "KAMYONET", "CEKICI", "GRAYDER", "ARAZOZ", "OTOBUS", "-", "IS_MAKINASI", "TRAKTOR", "MINIBUS", "SUPURGE", "FORKLIFT", "SILINDIR", "EKSKAVATOR", "DEPO"] -->
        <ol-style>
          <ol-style-icon :src="carMarkerIcon" :scale="0.08"></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'OTOMOBIL' && item.Speed > 0">
          <ol-style-icon :src="araba_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style
          v-else-if="item.AracSinifi === 'OTOMOBIL' && item.Speed === 0"
        >
          <ol-style-icon :src="araba_ikon_pasif" :scale="0.08"></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'KAMYON' && item.Speed > 0">
          <ol-style-icon :src="kamyon_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'KAMYON' && item.Speed === 0">
          <ol-style-icon :src="kamyon_ikon_pasif" :scale="0.08"></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'KAMYONET' && item.Speed > 0">
          <ol-style-icon :src="kamyonet_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style
          v-else-if="item.AracSinifi === 'KAMYONET' && item.Speed === 0"
        >
          <ol-style-icon
            :src="kamyonet_ikon_pasif"
            :scale="0.08"
          ></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'CEKICI' && item.Speed > 0">
          <ol-style-icon :src="cekici_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'CEKICI' && item.Speed === 0">
          <ol-style-icon :src="cekici_ikon_pasif" :scale="0.08"></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'GRAYDER' && item.Speed > 0">
          <ol-style-icon :src="greyder_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'GRAYDER' && item.Speed === 0">
          <ol-style-icon
            :src="greyder_ikon_pasif"
            :scale="0.08"
          ></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'ARAZOZ' && item.Speed > 0">
          <ol-style-icon :src="arazoz_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'ARAZOZ' && item.Speed === 0">
          <ol-style-icon :src="arazoz_ikon_pasif" :scale="0.08"></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'OTOBUS' && item.Speed > 0">
          <ol-style-icon :src="otobus_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'OTOBUS' && item.Speed === 0">
          <ol-style-icon :src="otobus_ikon_pasif" :scale="0.08"></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'TRAKTOR' && item.Speed > 0">
          <ol-style-icon :src="traktor_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'TRAKTOR' && item.Speed === 0">
          <ol-style-icon
            :src="traktor_ikon_pasif"
            :scale="0.08"
          ></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'MINIBUS' && item.Speed > 0">
          <ol-style-icon :src="minibus_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style v-else-if="item.AracSinifi === 'MINIBUS' && item.Speed === 0">
          <ol-style-icon
            :src="minibus_ikon_pasif"
            :scale="0.08"
          ></ol-style-icon>
        </ol-style>

        <ol-style v-if="item.AracSinifi === 'FORKLIFT' && item.Speed > 0">
          <ol-style-icon :src="forklift_ikon" :scale="0.08"></ol-style-icon>
        </ol-style>
        <ol-style
          v-else-if="item.AracSinifi === 'FORKLIFT' && item.Speed === 0"
        >
          <ol-style-icon
            :src="forklift_ikon_pasif"
            :scale="0.08"
          ></ol-style-icon>
        </ol-style>
      </ol-feature>
    </ol-source-vector>
  </ol-vector-layer>
</template>

<script>
import araba_ikon from '@/assets/png/araba-ikon.png'
import araba_ikon_pasif from '@/assets/png/araba-ikon-pasif.png'
import kamyonet_ikon from '@/assets/png/kamyonet-ikon.png'
import kamyonet_ikon_pasif from '@/assets/png/kamyonet-ikon-pasif.png'
import kamyon_ikon from '@/assets/png/kamyon-ikon.png.png'
import kamyon_ikon_pasif from '@/assets/png/kamyon-ikon-pasif.png'
import cekici_ikon from '@/assets/png/cekici-ikon.png'
import cekici_ikon_pasif from '@/assets/png/cekici-ikon-pasif.png'
import greyder_ikon from '@/assets/png/greyder-ikon.png'
import greyder_ikon_pasif from '@/assets/png/greyder-ikon-pasif.png'
import arazoz_ikon from '@/assets/png/arazoz-ikon.png'
import arazoz_ikon_pasif from '@/assets/png/arazoz-ikon-pasif.png'
import otobus_ikon from '@/assets/png/otobus-ikon.png'
import otobus_ikon_pasif from '@/assets/png/otobus-ikon-pasif.png'
import traktor_ikon from '@/assets/png/traktor-ikon.png'
import traktor_ikon_pasif from '@/assets/png/traktor-ikon-pasif.png'
import minibus_ikon from '@/assets/png/minibus-ikon.png'
import minibus_ikon_pasif from '@/assets/png/minibus-ikon-pasif.png'
import forklift_ikon from '@/assets/png/forklift-ikon.png'
import forklift_ikon_pasif from '@/assets/png/forklift-ikon-pasif.png'
import carMarkerIcon from '@/assets/png/car-placeholder.png'

export default {
  data() {
    return {
      araba_ikon: araba_ikon,
      araba_ikon_pasif: araba_ikon_pasif,
      kamyon_ikon: kamyon_ikon,
      kamyon_ikon_pasif: kamyon_ikon_pasif,
      kamyonet_ikon: kamyonet_ikon,
      kamyonet_ikon_pasif: kamyonet_ikon_pasif,
      cekici_ikon: cekici_ikon,
      cekici_ikon_pasif: cekici_ikon_pasif,
      greyder_ikon: greyder_ikon,
      greyder_ikon_pasif: greyder_ikon_pasif,
      arazoz_ikon: arazoz_ikon,
      arazoz_ikon_pasif: arazoz_ikon_pasif,
      otobus_ikon: otobus_ikon,
      otobus_ikon_pasif: otobus_ikon_pasif,
      traktor_ikon: traktor_ikon,
      traktor_ikon_pasif: traktor_ikon_pasif,
      minibus_ikon: minibus_ikon,
      minibus_ikon_pasif: minibus_ikon_pasif,
      forklift_ikon: forklift_ikon,
      forklift_ikon_pasif: forklift_ikon_pasif,
      carMarkerIcon: carMarkerIcon,
    }
  },
}
</script>
