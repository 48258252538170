<template>
  <div class="badge item3">
    <DeleteSvg width="0.8rem" />
  </div>
</template>


<script>
import DeleteSvg from "@/assets/icons/DeleteSvg.vue";

export default {
  props: {},

  components: {
    DeleteSvg,
  },
};
</script>

<style scoped>
.badge {
  background-color: red;
  display: inline-block;
  margin-left: 0.5rem;
  border-radius: 100%;
}

.item3 {
  background-color: #d22a24;
  padding: 0.4rem 0.4rem 0.37rem 0.4rem;
}

@media screen and (min-width: 3800px) {
  .item3 {
    padding: 0.6rem 0.6rem 0.57rem 0.6rem;
  }

  .item3 svg {
    width: 1.5rem;
  }
}
</style>