<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 34.676 27.637"
  >
    <path
      id="document-layout-left-svgrepo-com"
      d="M3,22.6H34.676M3,29.637H20.6M34.676,8.52H20.6m14.078,7.039H20.6m-14.782,0h4.927a4.372,4.372,0,0,0,1.855-.192,1.76,1.76,0,0,0,.769-.769,4.372,4.372,0,0,0,.192-1.855V7.816a4.372,4.372,0,0,0-.192-1.855,1.76,1.76,0,0,0-.769-.769A4.372,4.372,0,0,0,10.743,5H5.816a4.372,4.372,0,0,0-1.855.192,1.76,1.76,0,0,0-.769.769A4.372,4.372,0,0,0,3,7.816v4.927A4.372,4.372,0,0,0,3.192,14.6a1.76,1.76,0,0,0,.769.769A4.372,4.372,0,0,0,5.816,15.559Z"
      transform="translate(-1.5 -3.5)"
      fill="none"
      :stroke="activeIndex === 1 ? '#fff' : '#205c42'"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
    activeIndex: Number,
  },
};
</script>