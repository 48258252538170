<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 38.48 36.656"
  >
    <g id="map-pin-svgrepo-com" transform="translate(-2 -2)">
      <path
        id="Path_273"
        data-name="Path 273"
        d="M35.832,14.728V10.3a7.3,7.3,0,0,0-7.3-7.3H10.3A7.3,7.3,0,0,0,3,10.3v18.24a7.3,7.3,0,0,0,7.3,7.3h9.959"
        transform="translate(0 0)"
        fill="none"
        :stroke="selectedFilter === 'Ada' ? '#fff' : '#2892fc'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_274"
        data-name="Path 274"
        d="M23.118,14.876,3.82,4.57"
        transform="translate(0.676 1.294)"
        fill="none"
        :stroke="selectedFilter === 'Ada' ? '#fff' : '#2892fc'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_275"
        data-name="Path 275"
        d="M4.58,29.928,16.108,8.35"
        transform="translate(1.302 4.408)"
        fill="none"
        :stroke="selectedFilter === 'Ada' ? '#fff' : '#2892fc'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_276"
        data-name="Path 276"
        d="M22.208,12A8.19,8.19,0,0,0,14,20.208c0,5.472,6.165,10.032,8.208,10.032s8.208-4.469,8.208-10.032A8.208,8.208,0,0,0,22.208,12Z"
        transform="translate(9.064 7.416)"
        fill="none"
        :stroke="selectedFilter === 'Ada' ? '#fff' : '#2892fc'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_277"
        data-name="Path 277"
        d="M19.324,18.653a1.824,1.824,0,1,1,.7-.144A1.822,1.822,0,0,1,19.324,18.653Z"
        transform="translate(11.948 9.883)"
        :fill="selectedFilter === 'Ada' ? '#fff' : '#2892fc'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    selectedFilter: String,
  },
};
</script>