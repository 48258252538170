const getModule = {
  state: {
    vectors: null,
  },
  mutations: {
    setLayersData(state, data) {
      state.vectors = data
    },
    clearLayersData(state) {
      state.vectors = null
    },
  },
  actions: {
    setLayersData({ commit }, data) {
      commit("setLayersData", data)
    },
    clearLayersData({ commit }) {
      commit("clearLayersData")
    },
  },
  getters: {
    vectors: (state) => state.vectors,
  },
}

export default getModule
