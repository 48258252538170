const getModule = {
  state: {
    shared_users: null,
  },
  mutations: {
    setSharedUsersData(state, data) {
      state.shared_users = data
    },
    clearSharedUsersData(state) {
      state.shared_users = null
    },
  },
  actions: {
    setSharedUsersData({ commit }, data) {
      commit("setSharedUsersData", data)
    },
    clearSharedUsersData({ commit }) {
      commit("clearSharedUsersData")
    },
  },
  getters: {
    shared_users: (state) => state.shared_users,
  },
}

export default getModule
