<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    class="p-1"
    viewBox="0 0 16.588 30.168"
  >
    <g id="Page-1" transform="translate(16.588 0) rotate(90)">
      <g id="Dribbble-Light-Preview" transform="translate(0 0)">
        <g id="icons" transform="translate(0 0)">
          <path
            id="arrow_up-_337_"
            data-name="arrow_up-[#337]"
            d="M29.728,16.031h0a1.6,1.6,0,0,0,0-2.2L17.263.917A2.939,2.939,0,0,0,13,.917L.44,13.92a1.617,1.617,0,0,0-.015,2.2,1.489,1.489,0,0,0,2.15.016L14.065,4.22a1.484,1.484,0,0,1,2.133,0L27.6,16.031a1.455,1.455,0,0,0,2.131,0"
            fill="#3c4043"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>