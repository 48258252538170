<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 37.224 42.983"
  >
    <g id="katman_1" data-name="katman 1" transform="translate(0 -0.011)">
      <path
        id="Path_81"
        data-name="Path 81"
        d="M24.2,13.037a6.517,6.517,0,0,0,6.244-4.653h4.923a1.861,1.861,0,0,0,0-3.722H30.439a6.518,6.518,0,0,0-12.489,0H1.861a1.861,1.861,0,1,0,0,3.721h16.09A6.517,6.517,0,0,0,24.2,13.036ZM1.861,20.012a1.861,1.861,0,1,0,0,3.722H5.853a6.518,6.518,0,0,0,12.489,0h17.02a1.861,1.861,0,0,0,0-3.722H18.342a6.518,6.518,0,0,0-12.489,0H1.861ZM24.2,42.994a6.517,6.517,0,0,0,6.244-4.653h4.923a1.861,1.861,0,0,0,0-3.722H30.439a6.518,6.518,0,0,0-12.489,0H1.861a1.861,1.861,0,1,0,0,3.721h16.09A6.517,6.517,0,0,0,24.2,42.994Z"
        transform="translate(0 0)"
        fill="#3c4043"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>