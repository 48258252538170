<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" height="25.332" viewBox="0 0 42.302 25.332">
    <g id="sort-by-alphabet-svgrepo-com" transform="translate(-2 -6.25)">
      <path id="Path_258" data-name="Path 258" d="M25.028,7H3" transform="translate(0 0.902)" fill="none" stroke="#a4a4a4" stroke-linecap="round" stroke-width="2" />
      <path id="Path_259" data-name="Path 259" d="M18.419,12H3" transform="translate(0 6.916)" fill="none" stroke="#a4a4a4" stroke-linecap="round" stroke-width="2" />
      <path id="Path_260" data-name="Path 260" d="M14.014,17H3" transform="translate(0 12.93)" fill="none" stroke="#a4a4a4" stroke-linecap="round" stroke-width="2" />
      <path id="Path_261" data-name="Path 261" d="M11.4,29.252a1.652,1.652,0,1,0,3.013,1.356ZM22.815,7.9l1.506-.678a1.652,1.652,0,0,0-3.013,0Zm8.406,22.706a1.652,1.652,0,1,0,3.013-1.356Zm-2.1-8.688,1.507-.678ZM16.507,20.267a1.652,1.652,0,0,0,0,3.3Zm-2.1,10.34L24.321,8.58,21.308,7.224,11.4,29.252Zm19.825-1.356-3.6-8.01L27.616,22.6l3.6,8.01Zm-3.6-8.01L24.321,7.224,21.308,8.58,27.616,22.6Zm-1.507-.974H16.507v3.3H29.122Z" transform="translate(9.923)" fill="#a4a4a4" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
}
</script>
