const getModule = {
  state: {
    shared_layers: null,
  },
  mutations: {
    setSharedLayersData(state, data) {
      state.shared_layers = data
    },
    clearSharedLayersData(state) {
      state.shared_layers = null
    },
  },
  actions: {
    setSharedLayersData({ commit }, data) {
      commit("setSharedLayersData", data)
    },
    clearSharedLayersData({ commit }) {
      commit("clearSharedLayersData")
    },
  },
  getters: {
    shared_layers: (state) => state.shared_layers,
  },
}

export default getModule
