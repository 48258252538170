const getModule = {
  state: {
    selected: false,
    url: '',
    type: '',
  },
  mutations: {
    setKaratayAdaParsel(state, data) {
      if (state.type == data.type) {
        state.selected = false
        state.url = ''
        state.type = ''
      } else {
        state.selected = true
        state.url = data.url
        state.type = data.type
      }
    },
  },
  actions: {
    setKaratayAdaParsel({ commit }, data) {
      commit('setKaratayAdaParsel', data)
    },
  },
  getters: {},
}

export default getModule
