import { axiosInstance } from "@/helpers"
import axios from "axios"

const getModule = {
  state: {
    show: false,
    data: [],
  },
  mutations: {
    setVehicleData(state, data) {
      state.data = data
    },
    setVehicleToggle(state, data) {
      state.show = data
    },
  },
  actions: {
    setVehicleData({ commit, state }) {
      if (state.data.length == 0) {
        axiosInstance()
          .get(process.env.VUE_APP_BACKEND_URL + "/private/vehicle/index", {
            headers: {
              Authorization: this.state.auth.token,
            },
          })
          .then((response) => {
            commit("setVehicleData", response.data)
          })
      }
    },
    setVehicleToggle({ commit }, data) {
      commit("setVehicleToggle", data)
    },
  },
  getters: {
    allVehicle: (state) => state.data,
  },
}

export default getModule
