<template>
  <div class="main w-100 h-100">
    <MapContainer
      :showCluster="showCluster"
      :showProject="showProject"
      :showVehicle="showVehicle"
      :showUser="showUser"
      :showProfile="showProfile"
      :showVectorCoasters="showVectorCoasters"
      :getMapView="getMapView"
      :showProjectFunction="showProjectFunction"
      :showVehicleFunction="showVehicleFunction"
      :drawstart="drawstart"
      :drawend="drawend"
      :drawEnable="drawEnable"
      :drawType="drawType"
      :modifyEnabled="modifyEnabled"
      :selectedFeatures="selectedFeatures"
      :selectCondition="selectCondition"
      :zones="zones"
      :selectedScreen="selectedScreen"
      :mahallelerVisible="mahallelerVisible"
      :vectorCoasterNew="vectorCoasterNew"
      :setSelectedGeom="setSelectedGeom"
    />
    <HeaderMapVue
      :showProject="showProject"
      :showVehicle="showVehicle"
      :showUser="showUser"
      :showUserFunction="showUserFunction"
      :showProfile="showProfile"
      :showVectorCoasters="showVectorCoasters"
      :showProfileFunction="showProfileFunction"
      :showVectorCoastersFunction="showVectorCoastersFunction"
      @btnGetLocationClick="btnGetLocationClick"
      :sharedVectorScreen="sharedVectorScreen"
      :showSharedVectorScreen="showSharedVectorScreen"
      :showFilterFunction="showFilterFunction"
      :showFilter="showFilter"
      :selectFilter="selectFilter"
      :selectedFilter="selectedFilter"
      :vectorCoasterItems="vectorCoasterItems"
      :vectorCoasterNew="vectorCoasterNew"
      :toggleNewVectorMenuFunction="toggleNewVectorMenuFunction"
    />
    <MapTools
      :showProject="showProject"
      :showVehicle="showVehicle"
      :zoomIn="zoomIn"
      :zoomOut="zoomOut"
      :showUser="showUser"
      :showProfile="showProfile"
      :showVectorCoasters="showVectorCoasters"
      :drawTypeChange="drawTypeChange"
      :drawType="drawType"
      :zones="zones"
      :deleteFeature="deleteFeature"
      :toggleNewVectorMenuFunction="toggleNewVectorMenuFunction"
      :vectorCoasterNew="vectorCoasterNew"
    />
    <GeneralTools
      :showProject="showProject"
      :showVehicle="showVehicle"
      :showCluster="showCluster"
      :showUser="showUser"
      :showProfile="showProfile"
      :showVectorCoasters="showVectorCoasters"
      :showClusterFunction="showClusterFunction"
      :showProjectFunction="showProjectFunction"
      :changeSelectedScreen="changeSelectedScreen"
      :getMahaleler="getMahaleler"
      :vectorCoasterNew="vectorCoasterNew"
      :showVectorCoastersFunction="showVectorCoastersFunction"
      :showProfileFunction="showProfileFunction"
    />
  </div>
</template>

<script>
import { ref } from "vue"
import MapContainer from "@/components/Map/MapContainer.vue"
import HeaderMapVue from "@/components/Map/Header/HeaderMap.vue"
import MapTools from "@/components/Map/MapTools/MapTools.vue"
import GeneralTools from "@/components/Map/GeneralTools/GeneralTools.vue"
import { Collection } from "ol"
import Feature from "ol/Feature.js"
import Point from "ol/geom/Point.js"
import { fromLonLat } from "ol/proj"
import { GeoJSON } from "ol/format"
import Style from "ol/style/Style.js"
import Stroke from "ol/style/Stroke.js"
import Fill from "ol/style/Fill.js"
import Text from "ol/style/Text.js"
import { useToast } from "vue-toastification"
import * as Vue from "vue"
import { app } from "@/main"

export default {
  name: "App",
  components: {
    MapContainer,
    HeaderMapVue,
    MapTools,
    GeneralTools,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  inject: ["ol-selectconditions"],
  data() {
    return {
      drawEnable: ref(false),
      modifyEnabled: ref(false),
      selectedFeatures: ref(new Collection()),
      vectorLayer: ref(null),
      selectedGeom: ref(null),
      selectCondition: this["ol-selectconditions"].click,
      zones: ref([]),
      drawType: ref("None"),
      mapView: {},
      markers: {},
      showCluster: false,
      mahallelerVisible: false,
      showUser: false,
      showProject: false,
      showVehicle: false,
      showProfile: false,
      showVectorCoasters: false,
      sharedVectorScreen: false,
      showFilter: false,
      vectorCoasterItems: true,
      vectorCoasterNew: false,
      selectedFilter: "",
      selectedScreen: ref("https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png"),
    }
  },
  mounted() {
    // this.zones = new GeoJSON().readFeatures(this.geojsonObject);
    this.vectorLayer = this.$store.state.sources.vector_layer.vectorLayer

    document.onkeydown = (evt) => {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc"
      } else {
        isEscape = evt.keyCode === 27
      }
      if (isEscape) {
        if (this.drawEnabled) {
          this.modifyEnabled = true
          this.drawEnabled = false
          alert("Escape")
        }
      }
    }
  },
  methods: {
    setMahallelerVisible() {
      this.mahallelerVisible = !this.mahallelerVisible
    },
    async getMahaleler() {
      this.setMahallelerVisible()
      this.$store.dispatch("setKaratayMahalleler", {
        url: "https://api.karatay.bel.tr/cbs/mahalle/json",
        type: "karatay-mahalleler",
      })
      await this.axios.get("https://api.karatay.bel.tr/cbs/mahalle/json").then((res) => {
        if (this.mahallelerVisible) {
          this.vectorLayer.getSource().addFeatures(new GeoJSON().readFeatures(res.data))
          this.vectorLayer.getSource().forEachFeature((feature) => {
            let style = new Style({
              stroke: new Stroke({ color: "#20A6FF", width: 2 }),
              fill: new Fill({
                color: feature.values_.renk,
              }),
              text: new Text({
                text: feature.values_.name,
                scale: 1.3,
                fill: new Fill({
                  color: "#000000",
                }),
                stroke: new Stroke({
                  width: 1.3,
                }),
              }),
            })
            feature.setId(feature.values_.id)
            if (feature.id_) {
              feature.setStyle(style)
            }
          })
        } else {
          this.vectorLayer.getSource().forEachFeature((feature) => {
            if (feature.id_) {
              this.vectorLayer.getSource().removeFeature(feature)
            }
          })
        }
      })
    },
    deleteFeature() {
      console.log("this?.selectedGeom : ", this?.selectedGeom)
      const newZones = this.zones?.reduce((acc, curr) => {
        if (curr?.ol_uid !== this.selectedGeom?.ol_uid) {
          acc.push(curr)
        }
        return acc
      }, [])

      if (this?.selectedGeom && !this?.selectedGeom.id) {
        this.vectorLayer?.getSource().removeFeature(this.selectedGeom)
        this.toast.success("Katman silindi.", {
          timeout: 2000,
          hideProgressBar: true,
        })
      } else {
        alert("Lütfen bir katman seçiniz..")
      }
      this.zones = newZones
    },
    showProjectFunction(value) {
      this.showProject = value
      this.showUser = false
      this.showProfile = false
      this.showVectorCoasters = false
      this.sharedVectorScreen = false
      this.vectorCoasterNew = false
      this.vectorCoasterItems = true
      this.markers.source.refresh()
    },
    showVehicleFunction(value) {
      this.showVehicle = value
      this.showUser = false
      this.showProfile = false
      this.showVectorCoasters = false
      this.sharedVectorScreen = false
      this.vectorCoasterNew = false
      this.vectorCoasterItems = true
      this.markers.source.refresh()
    },
    showUserFunction(value) {
      this.showUser = value
      this.showProject = false
      this.markers.source.refresh()
    },
    showFilterFunction(value) {
      this.showFilter = value
      this.selectedFilter = ""
      this.markers.source.refresh()
    },
    selectFilter(filter) {
      this.selectedFilter = filter
    },
    showProfileFunction(value) {
      this.markers.source.refresh()
      if (!this.showProfile) {
        this.showProfile = value
        this.showUserFunction(false)
      } else {
        this.showProfile = value
        this.showUserFunction(true)
      }
    },
    showVectorCoastersFunction(value) {
      this.markers.source.refresh()
      if (!this.showVectorCoasters) {
        this.showVectorCoasters = value
        this.showUserFunction(false)
      } else {
        this.showVectorCoasters = value
        this.showUserFunction(true)
      }
    },
    toggleNewVectorMenuFunction() {
      this.markers.source.refresh()
      this.vectorCoasterItems = !this.vectorCoasterItems
      this.vectorCoasterNew = !this.vectorCoasterNew
      this.$store.dispatch("clearLayerData")
      this.$mapView.animate({ center: [32.6240873, 37.8756575] }, { zoom: 10 })
    },
    showSharedVectorScreen(value) {
      this.markers.source.refresh()
      if (!this.sharedVectorScreen) {
        this.sharedVectorScreen = value
        this.showVectorCoastersFunction(false)
      } else {
        this.sharedVectorScreen = value
        this.showVectorCoastersFunction(true)
      }
    },

    showClusterFunction() {
      this.showCluster = !this.showCluster
      this.markers.source.refresh()
    },
    changeSelectedScreen(screen) {
      this.markers.source.refresh()
      this.selectedScreen = screen
    },
    getMapView(view, markers) {
      this.mapView = view
      this.markers = markers
      app.config.globalProperties.$mapView = view
    },
    zoomIn() {
      this.markers.source.refresh()
      this.mapView.animate({ zoom: this.mapView.getZoom() + 1 })
    },
    zoomOut() {
      this.markers.source.refresh()
      this.mapView.animate({ zoom: this.mapView.getZoom() - 1 })
    },
    drawstart() {
      // console.log(event);
    },
    drawend(event) {
      const type = event.target.type_
      console.log(event)
      this.zones.push(event.feature)
      if (type === "Circle") {
        const circle = event.feature.getGeometry()
        this.$store.dispatch("newLayerData", {
          title: "Başlık",
          description: "Açıklama",
          type: "circle",
          color: "#205C42",
          data: {
            center: circle.getCenter(),
            radius: circle.getRadius(),
          },
        })
        console.log(`{\nradius: ${circle.getRadius()},\ncenter: ${circle.getCenter()}\n}`)
      } else if (type === "Polygon") {
        const drawJSON = new GeoJSON().writeFeatureObject(event.feature)
        this.$store.dispatch("newLayerData", {
          title: "Başlık",
          description: "Açıklama",
          type: "polygon",
          color: "#205C42",
          data: {
            coordinates: drawJSON.geometry.coordinates,
          },
        })
        console.log("geoJSON: ", drawJSON)
        console.log("drawJSON.geometry: ", JSON.stringify(drawJSON.geometry))
      } else if (type === "LineString") {
        const drawJSON = new GeoJSON().writeFeatureObject(event.feature)
        this.$store.dispatch("newLayerData", {
          title: "Başlık",
          description: "Açıklama",
          type: "line",
          color: "#205C42",
          data: {
            coordinates: drawJSON.geometry.coordinates,
          },
        })
      }

      this.modifyEnabled = true
      this.drawEnabled = false
    },
    drawTypeChange(type) {
      this.markers.source.refresh()
      if (!this.drawEnable) {
        this.drawType = type
        this.drawEnable = true
      } else {
        this.drawType = "None"
        this.drawEnable = false
      }
    },
    setSelectedGeom(event) {
      this.markers.source.refresh()
      this.modifyEnabled = false
      if (event.selected.length > 0 && this.zones?.length > 0 && !event.selected[0].id_) {
        this.modifyEnabled = true
      } else {
        this.modifyEnabled = false
      }
      this.selectedFeatures = event.target.getFeatures()
      this.selectedGeom = event.selected[0]
    },
    btnGetLocationClick() {
      if (this.markers.source.getFeatures()?.length > 0) {
        this.markers.source.refresh()
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          this.mapView.animate({ center: [position.coords.longitude, position.coords.latitude] }, { zoom: 17 })

          const feature = new Feature({
            geometry: new Point(fromLonLat([position.coords.longitude, position.coords.latitude], "EPSG:4326")),
          })

          this.markers.source.addFeature(feature)?.setZIndex(999)
        })
      }
    },
  },
}
</script>
