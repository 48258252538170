<template>
  <div class="position-relative">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="sharedVectorScreen ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: sharedVectorScreen ? 'visible' : 'hidden',
        width: sharedVectorScreen ? '20%' : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <div class="closeCanvas" @click="showSharedVectorScreen(false)">
          <ArrowLeftSvg width="0.5rem" />
        </div>
        <div class="title">Adsız Katman Paylaşım Ayarları</div>
        <div class="information">
          <span><ExclamationSvg width="2.5rem" /></span>
          <div class="description">
            Oluşturmuş olduğunuz vektör altlığı paylaşmak istediğiniz kişi veya kişileri seçerek altlığınızın seçtiğiniz
            kişiler tarafından da görüntülenmesini sağlayabilirsiniz.
          </div>
        </div>
        <div class="addInput">
          <label class="user-select-none">Paylaşılacak Kişi(ler)</label>
          <div class="input-group">
            <span class="input-group-text">
              <UserProfileNameSvg width="1.2rem" />
            </span>
            <input
              id="name"
              name="sharedName"
              type="text"
              v-model="sharedUser"
              placeholder="Adı Soyadı"
              class="form-control"
            />
          </div>
        </div>
        <div class="datePickerWrapper d-flex justify-content-between">
          <div class="datePickerDiv">
            <label class="form-check-label" for="flexSwitchCheckDefault">Paylaşım Sonlanma Tarihi</label>
            <div class="input-group">
              <span
                class="input-group-text"
                :style="{
                  backgroundColor: dateVisible ? '#ebebeb !important' : '#fff !important',
                }"
              >
                <CalenderSvg width="1.2rem" />
              </span>
              <input
                id="date"
                name="date"
                type="text"
                placeholder="15.08.2023"
                class="form-control"
                v-maska="sharedTime"
                data-maska="['##.##.####']"
                :disabled="dateVisible"
              />
            </div>
          </div>
          <div class="">
            <div class="form-check form-switch d-flex flex-column">
              <label class="form-check-label" for="flexSwitchCheckDefault">Süre Sınırı Yok</label>
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                :value="dateVisible"
                @change="changeDateVisible"
              />
            </div>
          </div>
        </div>
        <button
          class="block"
          :disabled="!sharedUser?.length"
          @click="test"
          :style="{
            borderColor: !sharedUser?.length ? '#a4a4a4' : ' #2aa83f',
            color: !sharedUser?.length ? '#a4a4a4' : '#2aa83f',
          }"
        >
          <CheckSvg width="0.9rem" :fill="!sharedUser?.length ? '#a4a4a4' : '#2aa83f'" />Kişi Ekle
        </button>
        <table class="table mt-5">
          <thead>
            <tr>
              <th scope="col">Paylaşılacak Kişi(ler)</th>
              <th scope="col" class="text-align-end">Sonlanma Tarihi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.$store.state.shared_users?.shared_users?.length <= 0">
              <td colspan="2" style="text-align: center">Veri bulunamadı</td>
            </tr>
            <tr v-for="(item, index) in this.$store.state.shared_users?.shared_users" :key="index">
              <td>{{ item.user.name + " " + item.user.surname }}</td>
              <td class="text-align-end">{{ item.is_end_date ? this.getDateFormat(item.end_date) : "Sınırsız" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
import ArrowLeftSvg from "@/assets/icons/ArrowLeftSvg.vue"
// import FooterLogoWhiteSvg from "@/assets/icons/FooterLogoWhiteSvg.vue";
import ExclamationSvg from "@/assets/icons/ExclamationSvg.vue"
import UserProfileNameSvg from "@/assets/icons/UserProfileNameSvg.vue"
import CalenderSvg from "@/assets/icons/CalenderSvg.vue"
import CheckSvg from "@/assets/icons/CheckSvg.vue"
import { vMaska } from "maska"
import { dateFormat } from "@/helpers"

export default {
  data() {
    return {
      dateVisible: false,
      sharedUser: "",
      sharedTime: {
        masked: "",
        unmasked: "",
        completed: false,
      },
    }
  },
  methods: {
    test() {
      console.log("TEST!")
    },
    changeDateVisible() {
      this.dateVisible = !this.dateVisible
    },
    getDateFormat(date){
      return dateFormat(date)
    }
  },
  props: {
    sharedVectorScreen: Boolean,
    showSharedVectorScreen: Function,
  },
  directives: { maska: vMaska },
  components: {
    ArrowLeftSvg,
    // FooterLogoWhiteSvg,
    ExclamationSvg,
    UserProfileNameSvg,
    CalenderSvg,
    CheckSvg,
  },
}
</script>

<style scoped>
.offcanvas {
  padding: 2rem 0 0 0;
}

.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}

.title {
  font-size: 1.2rem;
  text-decoration: 1px #205c42 solid underline;
  text-underline-offset: 3px;
  margin: 0 0 0 1rem;
}

.footer-logo {
  position: absolute;
  bottom: 30px;
  align-self: center;
}

.information {
  display: flex;
  align-items: center;
  /* text-align: center; */
  background-color: #205c42;
  color: #fff;
  border-radius: 8px;
  margin: 1rem 0.5rem 1.5rem 0.5rem;
  padding: 1rem;
}
.description {
  font-size: 0.75rem;
  line-height: 1.1rem;
  margin: 0 0 0 1rem;
}
.addInput {
  margin: 0 0.5rem 0 0.5rem;
}

.form-control {
  font-size: 0.8rem;
  color: #c8c8c8;
}

.form-control::placeholder {
  color: #a4a4a4;
}

.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.form-control:disabled {
  background-color: #ebebeb;
}

.input-group {
  margin: 0.5rem 0 0 0;
}
.input-group input {
  border-left-width: 0;
  padding: 0.8rem 0;
  border-radius: 8px;
}

.input-group-text {
  background-color: #fff;
  border-right-width: 0;
  border-radius: 8px;
}

.datePickerWrapper {
  margin: 1rem 0.5rem 0 0.5rem;
}

.form-check-input {
  width: 3rem !important;
  height: 1.5rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c8c8c8'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked {
  background-color: #205c42;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #dee2e6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c8c8c8'/%3e%3c/svg%3e");
}
.form-switch {
  display: flex;
  align-items: flex-end;
  padding: 0 0 0.5rem 0;
}
.block {
  display: block;
  border: 1px solid;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.7rem 7.5rem 0.7rem 7.5rem;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  margin: 2rem 0 0 0.5rem !important;
}
.block svg {
  width: 1.1rem;
  margin-right: 1rem;
  padding-bottom: 0.3rem;
}

.text-align-end {
  text-align: end;
}

@media screen and (min-width: 3800px) {
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }

  .title {
    font-size: 2.1rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
    margin: 1.5rem 0 0 1rem;
  }

  .footer-logo {
    bottom: 30px;
  }
  .footer-logo svg {
    height: 4.8rem;
  }

  .information {
    margin: 1rem 0.5rem 2.5rem 0.5rem;
    padding: 1rem;
  }
  .information svg {
    width: 3.5rem;
  }
  .description {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 0 1rem;
  }

  label {
    font-size: 1.8rem;
  }

  .input-group svg {
    margin: 0 0.5rem 0 0;
    width: 2rem;
  }
  .input-group input {
    padding: 1.5rem 0;
  }
  .form-control {
    border-left: 0;
    font-size: 1.6rem;
    color: #c8c8c8;
  }

  .form-check-input {
    width: 6rem !important;
    height: 3rem !important;
  }

  .block {
    padding: 1.2rem 17.1rem 1.2rem 17.2rem;
    font-size: 30px;
    margin: 4rem 1rem 0 1rem !important;
  }
  .block svg {
    width: 1.8rem;
    margin-right: 1rem;
    padding-bottom: 0.3rem;
  }

  .table tr {
    font-size: 1.8rem;
  }
}
</style>
