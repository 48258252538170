<template>
  <svg
    id="layers-svgrepo-com_1_"
    data-name="layers-svgrepo-com (1)"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 53.305 55.781"
    class="mb-2"
  >
    <path
      id="Path_1"
      data-name="Path 1"
      d="M2.89,16.471,27.215,28.525a2.327,2.327,0,0,0,2.067,0L53.606,16.47a2.328,2.328,0,0,0,0-4.172L29.282.242a2.327,2.327,0,0,0-2.067,0L2.89,12.3a2.328,2.328,0,0,0,0,4.172ZM28.248,4.927l19.081,9.458L28.248,23.841,9.167,14.385Z"
      transform="translate(-1.595)"
      fill="#3c4043"
    />
    <path
      id="Path_2"
      data-name="Path 2"
      d="M51.538,27.757,28.248,39.3,4.957,27.757A2.328,2.328,0,0,0,2.89,31.929L27.215,43.985a2.324,2.324,0,0,0,2.067,0L53.606,31.929a2.328,2.328,0,0,0-2.067-4.172Z"
      transform="translate(-1.595 -6.163)"
      fill="#3c4043"
    />
    <path
      id="Path_3"
      data-name="Path 3"
      d="M51.538,39.174,28.248,50.716,4.957,39.173A2.328,2.328,0,0,0,2.89,43.345L27.215,55.4a2.324,2.324,0,0,0,2.067,0L53.606,43.345a2.328,2.328,0,0,0-2.067-4.171Z"
      transform="translate(-1.595 -8.72)"
      fill="#3c4043"
    />
    <path
      id="Path_4"
      data-name="Path 4"
      d="M51.538,50.59,28.248,62.132,4.957,50.589A2.328,2.328,0,0,0,2.89,54.761L27.215,66.816a2.324,2.324,0,0,0,2.067,0L53.606,54.761a2.328,2.328,0,0,0-2.067-4.171Z"
      transform="translate(-1.595 -11.278)"
      fill="#3c4043"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>

<style scoped>
svg {
  margin-top: 0.9rem;
}
</style>