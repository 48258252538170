<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 30.546 30.542"
  >
    <path
      id="call-svgrepo-com"
      d="M12.145,24.658C2.062,14.573,2.544,6.947,7.157,3.488l1.022-.761A2.417,2.417,0,0,1,11.6,3.3l2.574,3.7a2.445,2.445,0,0,1-.407,3.244l-2.478,2.142a1.939,1.939,0,0,0-.644,2.121,19.3,19.3,0,0,0,4.722,6.921,19.344,19.344,0,0,0,6.921,4.724,1.941,1.941,0,0,0,2.122-.645l2.142-2.474a2.445,2.445,0,0,1,3.244-.407l3.71,2.575a2.412,2.412,0,0,1,.577,3.416l-.766,1.032C29.861,34.262,22.231,34.743,12.145,24.658Z"
      transform="translate(-4.008 -2.257)"
      fill="#a4a4a4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: String,
  },
};
</script>