<template>
  <div :id="galleryID" class="imagesContainer">
    <a
      v-for="(image, key) in imagesData"
      :key="key"
      :href="image.largeURL"
      :data-pswp-width="image.width"
      :data-pswp-height="image.height"
      target="_blank"
      rel="noreferrer"
      class="imagesItem"
    >
      <img :src="image.thumbnailURL" alt="" />
    </a>
  </div>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

export default {
  name: 'SimpleGallery',
  props: {
    galleryID: String,
    images: Array,
  },
  setup(props) {
    return {
      imagesData: props.images,
    }
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: '#' + this.$props.galleryID,
        children: 'a',
        pswpModule: () => import('photoswipe'),
      })
      this.lightbox.init()
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy()
      this.lightbox = null
    }
  },
  methods: {},
}
</script>

<style>
.imagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.5rem 1.5rem 5rem 1.5rem;
  overflow-y: scroll;
}

.imagesContainer::-webkit-scrollbar {
  display: none;
}

.imagesItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0.25rem 0 0.25rem;
  border-radius: 15px;
}

.imagesItem img {
  width: 9.87rem;
  height: 7rem;
  border-radius: 15px;
}
</style>