<template>
  <div class="item user-select-none">
    <div class="itemContainer">
      <div>{{ title }}</div>
      <div class="d-flex">
        <div class="badge" @click="$emit('showVector')">
          <EyeSvg width="0.7rem" />
        </div>
        <div class="badge2" @click="changeCollapseVisible">
          <ArrowDownSvg width="0.6rem" v-if="!collapseVisible" />
          <ArrowUpSvg width="0.6rem" v-else />
        </div>
      </div>
    </div>
    <div v-if="collapseVisible">
      <div class="divider"></div>
      <div class="profilPhoto">
        <div class="d-flex">
          <div class="photoWrapper">
            <img src="@/assets/png/defaultProfile.png" alt="" />
          </div>
          <div class="userInfo">
            <div class="userTag">Oluşturan:</div>
            <div class="userName">{{ name }}</div>
            <div class="userMudurluk">{{ management }}</div>
          </div>
        </div>
        <div v-if="is_end_date" class="lastDate">
          <div class="title">Sona Erme Tarihi:</div>
          <div class="date">{{ this.getDateFormat(end_date) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EyeSvg from "@/assets/icons/EyeSvg.vue"
import ArrowDownSvg from "@/assets/icons/ArrowDownSvg.vue"
import ArrowUpSvg from "@/assets/icons/ArrowUpSvg.vue"
import { dateFormat } from "@/helpers"

export default {
  data() {
    return {
      collapseVisible: false,
    }
  },

  props: {
    management: String,
    name: String,
    title: String,
    end_date: String,
    is_end_date: Boolean,
  },

  components: {
    EyeSvg,
    ArrowDownSvg,
    ArrowUpSvg,
  },

  methods: {
    changeCollapseVisible() {
      this.collapseVisible = !this.collapseVisible
    },
    getDateFormat(date) {
      return dateFormat(date)
    },
  },
}
</script>

<style scoped>
.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: initial;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  padding: 0 1rem;
  margin: 10px 0px;
  font-size: 0.78rem;
  color: #205c42;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
}

.badge {
  margin-right: 0.5rem;
  background-color: #ff8800;
  border-radius: 100%;
  padding: 0.4rem 0.3rem 0.3rem 0.3rem;
}

.badge2 {
  background-color: #ebebeb;
  border-radius: 100%;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
}

.divider {
  margin: 0.5rem 0 0.5rem 0;
  width: 100%;
  height: 1px;
  background-color: #f8f8f8;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0.5rem;
}
.userTag {
  font-size: 0.65rem;
  color: #205c42;
}
.userName {
  margin-top: -3px;
  font-size: 0.8rem;
  color: #a4a4a4;
}
.userMudurluk {
  color: #a4a4a4;
  line-height: 0.5rem;
  font-size: 0.6rem;
}

.profilPhoto {
  display: flex;
  justify-content: space-between;
  margin: 0 -0.4rem;
}
.photoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  border: 2px #f8f8f8 solid;
  border-radius: 50%;
}
.profilPhoto img {
  max-width: 2.4rem;
}

.lastDate {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 0.6rem;
}

.date {
  line-height: 0.8rem;
  font-size: 0.7rem;
  color: #a4a4a4;
}

@media screen and (min-width: 3800px) {
  .item {
    padding: 0.9rem 1.2rem;
    margin: 20px 0px;
    font-size: 1.5rem;
  }

  .badge {
    margin-right: 1 rem;
    padding: 0.6rem 0.5rem 0.5rem 0.5rem;
  }

  .badge2 {
    padding: 0 0.6rem 0.3rem 0.6rem;
  }

  .badge svg {
    width: 1.5rem;
  }

  .badge2 svg {
    width: 1.5rem;
  }

  .divider {
    margin: 0.8rem 0 0.8rem 0;
  }

  .userInfo {
    margin-left: 0.8rem;
  }
  .userTag {
    font-size: 1.1rem;
  }
  .userName {
    margin-top: -5px;
    font-size: 1.1rem;
  }
  .userMudurluk {
    line-height: 0.8rem;
    font-size: 0.9rem;
  }

  .profilPhoto {
    margin: 0 -0.4rem;
  }
  .photoWrapper {
    width: 4rem;
    height: 4rem;
  }
  .profilPhoto img {
    max-width: 3.5rem;
  }

  .lastDate {
    font-size: 1.1rem;
  }

  .date {
    line-height: 1rem;
    font-size: 1.1rem;
  }
}
</style>
