<template>
  <div class="position-relative">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="showVehicle ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: showVehicle ? 'visible' : 'hidden',
        width: showVehicle ? '20%' : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <div class="closeCanvas" @click="showVehicleFunction(false)">
          <ArrowLeftSvg width="0.5rem" />
        </div>
        <div class="p-3">
          <div>
            <div
              :style="{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 0 .5rem 0',
                gap: '1rem',
              }"
            >
              <div class="infoDetailBox">
                <div
                  class="infoDetailTitle"
                  :style="{
                    textDecoration: '2px #ECA90C solid underline',
                  }"
                >
                  Araç Sınıfı
                </div>
                <div class="infoDetailDesc">
                  {{ `${siniflar[selectedVehicle?.AracSinifi] ?? '-'}` }}
                </div>
              </div>
              <div class="infoDetailBox">
                <div
                  class="infoDetailTitle"
                  :style="{
                    textDecoration: '2px #28A746 solid underline',
                  }"
                >
                  Plaka No
                </div>
                <div class="infoDetailDesc">
                  {{ `${selectedVehicle?.PlakaNo}` }}
                </div>
              </div>
            </div>
            <div
              :style="{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                margin: '0 0 .5rem 0',
                gap: '1rem',
              }"
            >
              <div
                class="infoDetailBox"
                :style="{
                  width: '30rem',
                }"
              >
                <div
                  class="infoDetailTitle"
                  :style="{
                    textDecoration: '2px #0CA0EC solid underline',
                  }"
                >
                  Adres
                </div>
                <div class="infoDetailDesc">
                  {{ `${selectedVehicle?.Address}` }}
                </div>
              </div>
            </div>
          </div>
          <div
            :style="{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 0 .5rem 0',
              gap: '1rem',
            }"
          >
            <div class="infoDetailBox">
              <div
                class="infoDetailTitle"
                :style="{
                  textDecoration: '2px #BE1B1B solid underline',
                }"
              >
                Hız
              </div>
              <div class="infoDetailDesc">
                {{ `${selectedVehicle?.Speed}` }}
              </div>
            </div>
            <div class="infoDetailBox">
              <div
                class="infoDetailTitle"
                :style="{
                  textDecoration: '2px #7d518f solid underline',
                }"
              >
                Course
              </div>
              <div class="infoDetailDesc">
                {{
                  selectedVehicle?.Course === -10000
                    ? 'Hareketsiz'
                    : `${selectedVehicle?.Course}`
                }}
              </div>
            </div>
          </div>
          <pre>{{ selectedVehicle }}</pre>
        </div>
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
import ArrowLeftSvg from '@/assets/icons/ArrowLeftSvg.vue'
// import FooterLogoWhiteSvg from '@/assets/icons/FooterLogoWhiteSvg.vue'

export default {
  props: {
    showVehicle: Boolean,
    showVehicleFunction: Function,
    setSelectedVehicle: Function,
    selectedVehicle: Object,
  },

  data() {
    return {
      siniflar: {
        OTOMOBIL: 'Otomobil',
        KAMYON: 'Kamyon',
        KAMYONET: 'Kamyonet',
        CEKICI: 'Çekici',
        GRAYDER: 'Gradyer',
        ARAZOZ: 'Arozöz',
        OTOBUS: 'Otobüs',
        IS_MAKINASI: 'İş Makinesi',
        TRAKTOR: 'Traktör',
        MINIBUS: 'Minibüs',
        SUPURGE: 'Süpürge',
        FORKLIFT: 'Folklift',
        SILINDIR: 'Silindir',
        EKSKAVATOR: 'Ekskavatör',
        DEPO: 'Depo',
      },
    }
  },
  components: {
    ArrowLeftSvg,
    // FooterLogoWhiteSvg,
  },
  computed: {
    vehicle() {
      return this.$store.state.arvento.data
    },
  },
  watch: {
    vehicle: function (newVal, oldVal) {
      // watch it
      if (newVal !== oldVal) {
        const data = this.$store.state.arvento.data?.reduce((acc, curr) => {
          if (this.selectedVehicle.PlakaNo === curr.PlakaNo) {
            acc = curr
          }
          return acc
        }, {})
        this.setSelectedVehicle(data)
      }
    },
  },
}
</script>

<style scoped>
.offcanvas {
  padding-top: 2rem;
}
.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}
.value {
  font-size: 1.1rem;
  /* text-decoration: 1px #205c42 solid underline; */
  /* text-underline-offset: 3px; */
}

.infoDetailBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 8rem;
  padding: 10px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.infoDetailTitle {
  font-weight: 500;
  margin: 0 0 0.5rem 0;
  text-underline-offset: 5px;
}

.infoDetailDesc {
  font-size: 0.9rem;
  font-weight: 100;
}

.footer-logo {
  position: fixed;
  display: flex;
  align-self: center;
  bottom: 30px;
}

@media screen and (min-width: 3800px) {
  .footer-logo {
    bottom: 30px;
  }
  .offcanvas {
    padding-top: 1.9rem;
  }
  .value {
    font-size: 2.1rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
    margin: 0 0 -1rem 1rem;
  }
  .footer-logo svg {
    height: 4.8rem;
  }
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }
}
</style>
