<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 49.189 49.19"
  >
    <g id="location-svgrepo-com" transform="translate(-1.5 -1.5)">
      <path
        id="Path_85"
        data-name="Path 85"
        d="M40.925,22.963A17.963,17.963,0,1,1,22.963,5,17.963,17.963,0,0,1,40.925,22.963Z"
        transform="translate(3.132 3.132)"
        fill="none"
        stroke="#3c4043"
        stroke-width="3"
      />
      <path
        id="Path_86"
        data-name="Path 86"
        d="M19,12h5.132"
        transform="translate(25.057 14.095)"
        fill="none"
        stroke="#3c4043"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_87"
        data-name="Path 87"
        d="M3,12H8.132"
        transform="translate(0 14.095)"
        fill="none"
        stroke="#3c4043"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_88"
        data-name="Path 88"
        d="M12,19v5.132"
        transform="translate(14.095 25.057)"
        fill="none"
        stroke="#3c4043"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_89"
        data-name="Path 89"
        d="M12,3V8.132"
        transform="translate(14.095 0)"
        fill="none"
        stroke="#3c4043"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_90"
        data-name="Path 90"
        d="M20.929,14.964A5.964,5.964,0,1,1,14.964,9,5.964,5.964,0,0,1,20.929,14.964Z"
        transform="translate(11.13 11.13)"
        fill="#3c4043"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>