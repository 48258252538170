<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 25.786 30.337"
  >
    <path
      id="share-svgrepo-com_1_"
      data-name="share-svgrepo-com (1)"
      d="M19.128,7.056a5.068,5.068,0,1,1,1.462,3.55l-7.031,4.787a5.057,5.057,0,0,1-.276,2.908l7.71,5.065a5.052,5.052,0,1,1-1.865,3.915,5.021,5.021,0,0,1,.438-2.056L11.919,20.2a5.055,5.055,0,1,1,.639-6.952l6.814-4.64A5.032,5.032,0,0,1,19.128,7.056Z"
      transform="translate(-3.5 -2)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>