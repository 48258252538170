<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 33.987 33.987"
  >
    <path
      id="Path_70"
      data-name="Path 70"
      d="M0,0H13.328"
      transform="translate(18.66 16.994)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_71"
      data-name="Path 71"
      d="M4.913,10,.06,5.143h0a.205.205,0,0,1,0-.29h0L4.913,0"
      transform="translate(18.744 11.996)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
    <path
      id="Path_72"
      data-name="Path 72"
      d="M21.657,3.332V2.5h0a2.5,2.5,0,0,0-2.5-2.5H3.332A3.332,3.332,0,0,0,0,3.332V26.655a3.332,3.332,0,0,0,3.332,3.332H19.158a2.5,2.5,0,0,0,2.5-2.5h0v-.833"
      transform="translate(2 2)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
    />
  </svg>
</template>


<script>
export default {
  props: {
    height: String,
  },
};
</script>