<template>
  <MapPage />
</template>

<script>

import MapPage from "./pages/MapPage.vue";


export default {
  name: 'App',
  components: {
    MapPage,
  },
  created() {
    if (localStorage.getItem('data')) {
      const data = JSON.parse(localStorage.getItem('data'))
      this.$store.dispatch('setLoggedData', data)
    }
  },
}
</script>

<style>
@import 'ol/ol.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b2b2b;
  margin-top: 60px;
}

html,
body,
#map,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  overflow: hidden;
  height: 100vh;
}
</style>
