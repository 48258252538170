<template>
  <svg
    :style="style"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :class="classProps"
    viewBox="0 0 56.518 56.518"
  >
    <g
      id="map-svgrepo-com_1_"
      data-name="map-svgrepo-com (1)"
      transform="translate(-2 -2)"
    >
      <path
        id="secondary"
        d="M2,21.781V7.652A5.652,5.652,0,0,1,7.652,2H25.681l11.3,19.781Zm22.607,5.652V58.518H52.866a5.652,5.652,0,0,0,5.652-5.652V27.433Z"
        transform="translate(0 0)"
        fill="#808181"
      />
      <path
        id="primary"
        d="M32.181,2H52.866a5.652,5.652,0,0,1,5.652,5.652v14.13H43.484ZM18.955,27.433H2V52.866a5.652,5.652,0,0,0,5.652,5.652h11.3Z"
        transform="translate(0 0)"
        fill="#3c4043"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
    style: Object,
    classProps: String,
  },
};
</script>