<template>
  <button class="btnContainer">
    <div class="d-flex">
      <slot></slot>
      <div class="d-flex ms-3 btnText">
        <div class="text">
          {{ text }}
        </div>
        <div
          v-if="count"
          class="badge ms-3"
          :style="{ backgroundColor: badgeBackgroundColor }"
        >
          {{ count }}
        </div>
      </div>
    </div>
    <ArrowRightSvg width="1rem" />
  </button>
</template>

<script>
import ArrowRightSvg from "@/assets/icons/ArrowRightSvg.vue";
export default {
  props: {
    text: String,
    count: Number,
  },
  components: { ArrowRightSvg },
  computed: {
    badgeBackgroundColor() {
      if (this?.text === "Faliyetleriniz") {
        return "#205c42";
      } else if (this.text === "Katmanlarınız") {
        return "#ff8800";
      } else {
        return "#2DA125";
      }
    },
  },
};
</script>

<style scoped>
.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: none;
  padding: 0.7rem 1rem;
  margin: 0.3rem 0;
  box-shadow: rgba(149, 157, 165, 0.13) 0px 8px 24px;
}
.badge {
  background-color: #ff8800;
  padding: 0.45rem 0.5rem 0.2rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 15px;
}
@media screen and (min-width: 3800px) {
  .btnContainer {
    padding: 1.2rem 2.5rem 1.2rem 1.5rem;
    margin: 0.5rem 0;
  }
  .badge {
    padding: 0.8rem 1.2rem 0.5rem 1.2rem;
    font-size: 1.4rem;
    border-radius: 25px;
  }
  :slotted(svg) {
    width: 2.5rem;
  }
  .btnText {
    margin-left: 2rem !important;
  }
  .text {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
}
</style>