<template>
  <svg
    id="katman_1"
    data-name="katman 1"
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 144.622 167.722"
  >
    <g id="Group_12" data-name="Group 12">
      <path
        id="Path_7"
        data-name="Path 7"
        d="M105.66,590.828a2.4,2.4,0,0,0,1.422-2.4v-.163c0-2.1-1.518-3.1-3.746-3.1H99.88v11.386h3.57c2.228,0,3.746-1.017,3.746-3.117v-.144a2.459,2.459,0,0,0-1.534-2.455m-3.568-3.536h1.161c.841,0,1.63.227,1.63,1.211v.179c0,1.049-.71,1.292-1.436,1.292h-1.356ZM105,593.109c0,1.113-.774,1.324-1.63,1.324h-1.275V591.7h1.356c.774,0,1.55.259,1.55,1.227v.176Z"
        transform="translate(-73.229 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M141.41,596.546h6.329v-2.132h-4.117v-2.583h3.053v-1.94h-3.053v-2.58h4.117V585.16H141.41Z"
        transform="translate(-103.677 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M181.462,585.16H179.25v11.386h5.958v-2.132h-3.746Z"
        transform="translate(-131.42 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M215.7,596.546h6.329v-2.132h-4.117v-2.583h3.053v-1.94h-3.053v-2.58h4.117V585.16H215.7Z"
        transform="translate(-158.144 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M257.254,585.16H253.54v11.386h3.714c2.244,0,3.794-1.47,3.794-4.216v-3c0-2.778-1.55-4.168-3.794-4.168m1.6,6.62c0,2.228-.792,2.647-1.857,2.647h-1.243v-7.138H257c1.534,0,1.857,1.049,1.857,2.276v2.212Z"
        transform="translate(-185.887 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M296.747,574.069a1.024,1.024,0,0,0-1.017-1.019,1.01,1.01,0,0,0-1,1.019,1.009,1.009,0,0,0,2.017,0"
        transform="translate(-216.086 -420.14)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <rect
        id="Rectangle_6"
        data-name="Rectangle 6"
        width="2.212"
        height="11.386"
        transform="translate(78.546 156.141)"
        fill="#fff"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M316.046,589.974l-1.985-4.814H311.75l3.183,7.285v4.1h2.212v-4.1l3.213-7.285h-2.295Z"
        transform="translate(-228.564 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M357.06,596.546h6.329v-2.132h-4.12v-2.583h3.053v-1.94h-3.053v-2.58h4.12V585.16H357.06Z"
        transform="translate(-261.784 -429.019)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_15"
        data-name="Path 15"
        d="M396.16,587.713c0-.857.534-1.31,1.47-1.31s1.534.566,1.534,1.777h2.18a3.527,3.527,0,0,0-3.682-3.81c-2.183,0-3.7,1.259-3.7,3.343,0,4.037,5.313,2.842,5.313,5.006,0,.937-.678,1.374-1.614,1.374s-1.646-.55-1.646-1.873h-2.2a3.655,3.655,0,0,0,3.842,3.941c2.276,0,3.826-1.294,3.826-3.458,0-4.069-5.329-2.73-5.329-4.99"
        transform="translate(-288.735 -428.44)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_16"
        data-name="Path 16"
        d="M436.207,574.069a1.024,1.024,0,0,0-1.017-1.019,1.01,1.01,0,0,0-1,1.019,1.009,1.009,0,0,0,2.017,0"
        transform="translate(-318.333 -420.14)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <rect
        id="Rectangle_7"
        data-name="Rectangle 7"
        width="2.212"
        height="11.386"
        transform="translate(115.758 156.141)"
        fill="#fff"
      />
      <path
        id="Path_17"
        data-name="Path 17"
        d="M23.4,462.3H15.911L9.147,448.047H6.666V462.3H0V428H6.666v14.11H9.147L15.911,428H23.4l-8.515,17.029Z"
        transform="translate(0 -313.795)"
        fill="#fff"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M84.889,455.533H75.011l-1.846,6.762H66.4l9.974-34.3h7.25l9.83,34.3H86.69Zm-8.467-5.79h7.055l-3.456-13.43-3.6,13.43Z"
        transform="translate(-48.682 -313.787)"
        fill="#fff"
      />
      <path
        id="Path_19"
        data-name="Path 19"
        d="M162.85,462.3V428h10.8c6.812,0,11.386,4.429,11.386,12.408v.974c0,5.3-2.287,8.856-5.937,10.511l5.742,10.412h-7.3l-4.574-8.856h-3.455V462.3Zm6.666-27.879v12.651h3.309c2.919,0,5.6-1.265,5.6-5.79v-.974c0-4.72-2.578-5.886-5.6-5.886Z"
        transform="translate(-119.396 -313.795)"
        fill="#fff"
      />
      <path
        id="Path_20"
        data-name="Path 20"
        d="M256.159,455.533h-9.878l-1.846,6.762H237.67l9.974-34.3h7.25l9.83,34.3H257.96Zm-8.467-5.79h7.055l-3.453-13.43Z"
        transform="translate(-174.251 -313.787)"
        fill="#fff"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M336.816,434.471h-7.1V462.3h-6.668V434.471H315.99V428h20.826Z"
        transform="translate(-231.673 -313.795)"
        fill="#fff"
      />
      <path
        id="Path_22"
        data-name="Path 22"
        d="M392.332,455.533h-9.878l-1.849,6.762H373.84l9.974-34.3h7.25l9.83,34.3H394.13l-1.8-6.762Zm-8.467-5.79h7.055l-3.456-13.43-3.6,13.43Z"
        transform="translate(-274.086 -313.787)"
        fill="#fff"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M454.385,449.945,444.8,428h6.956l5.988,14.5,6.081-14.5h6.908l-9.683,21.945V462.3h-6.666Z"
        transform="translate(-326.112 -313.795)"
        fill="#fff"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M226.328,160.644a.206.206,0,0,0-.1-.053h0a.442.442,0,0,0-.085-.021h0a1.109,1.109,0,0,0-.131-.021,7.009,7.009,0,0,0-1.094,0c-.051.008-.192.037-.216.045h0a.24.24,0,0,0-.1.053L208.89,190.438a3.519,3.519,0,0,1,.947-.088h0l11.871-23.321h.12L210.328,190a3.061,3.061,0,0,1,.79-.275,9.012,9.012,0,0,1,2.482-.163l10.041-25.066h.128l-9.718,24.837a8.067,8.067,0,0,1,2.751-.483,10.766,10.766,0,0,1,2.2.1l6.03-27.084h.12l-5.75,27.014a10.89,10.89,0,0,1,3.346-.291c.563.029,1.225.083,1.822.149.291.035.52.061.688.085l.125-26.659h.149l.1,26.665h.008c.168-.024.414-.056.731-.091.6-.067,1.262-.123,1.822-.149a11.055,11.055,0,0,1,3.3.278l-5.745-27h.123l6.033,27.092a10.41,10.41,0,0,1,2.239-.1,8.256,8.256,0,0,1,2.7.462l-9.71-24.816h.128l10.03,25.04c.013.011.029.019.04.027a8.993,8.993,0,0,1,2.495.16,3.183,3.183,0,0,1,.691.224l-11.431-22.918h.12l11.765,23.2a1.114,1.114,0,0,1,.136.123,3.5,3.5,0,0,1,.945.088l-15.709-29.792Z"
        transform="translate(-153.151 -117.691)"
        fill="#fff"
      />
      <path
        id="Path_25"
        data-name="Path 25"
        d="M165.7,105.928c3.837-11.087,12.656-16.344,18.985-18.758H165.7Z"
        transform="translate(-121.485 -63.91)"
        fill="#fff"
      />
      <path
        id="Path_26"
        data-name="Path 26"
        d="M323.5,105.936V87.18H304.51c6.329,2.412,15.148,7.669,18.985,18.756"
        transform="translate(-223.256 -63.917)"
        fill="#fff"
      />
      <path
        id="Path_27"
        data-name="Path 27"
        d="M165.7,36.5V36h0V15.116H144.322L129.206,0,114.09,15.116H92.711v20.09h0V36.5L77.6,51.609,92.714,66.725V88.1h20.856V82.356H107.8v-29h0V51.947s2.679-18.067,21.491-21.672h.059c18.8,3.61,21.125,21.672,21.125,21.672v1.43h.008V82.359h-5.62V88.1H165.7V67.322h0v-.6l15.114-15.114L165.7,36.5ZM129.206,8.125l6.988,6.988H122.215l6.991-6.991Zm-36.5,36.5V58.594l-6.988-6.986Zm67.24,37.736h-2.8V56.545c0-28.786-26.926-32.685-27.988-32.826a.074.074,0,0,0-.027,0c-.005,0-.024,0-.024,0-1.062.141-27.988,4.043-27.988,32.826V82.356H98.456V20.861h61.492V82.353ZM165.7,44.62l6.988,6.988L165.7,58.6Z"
        transform="translate(-56.894)"
        fill="#fff"
      />
      <path
        id="Path_28"
        data-name="Path 28"
        d="M239.367,396.03H204.841a.531.531,0,1,0,0,1.062h34.526a.531.531,0,1,0,0-1.062"
        transform="translate(-149.793 -290.355)"
        fill="#fff"
      />
      <path
        id="Path_29"
        data-name="Path 29"
        d="M241.094,271.52a1.162,1.162,0,0,0-.627-.443,4.953,4.953,0,0,0-1.5-.152,1.3,1.3,0,0,0-.55-.47,9.57,9.57,0,0,0-3.063-.3,6.392,6.392,0,0,0-2.818-.52,19.6,19.6,0,0,0-2.188.072l-.3.021a9.2,9.2,0,0,0-2.778-.339,18.79,18.79,0,0,0-3.229.229,18.765,18.765,0,0,0-3.229-.229,9.187,9.187,0,0,0-2.775.339l-.3-.021a20.335,20.335,0,0,0-2.188-.072,6.329,6.329,0,0,0-2.82.52,9.493,9.493,0,0,0-3.063.3,1.311,1.311,0,0,0-.552.47,5.049,5.049,0,0,0-1.5.152,1.19,1.19,0,0,0-.627.443.226.226,0,0,0,.1.326.286.286,0,0,0,.131.032.27.27,0,0,0,.232-.123.674.674,0,0,1,.342-.227,4.373,4.373,0,0,1,1.46-.109.261.261,0,0,0,.3-.195c.019-.091.077-.2.379-.36a10.069,10.069,0,0,1,2.863-.227.281.281,0,0,0,.179-.051,5.388,5.388,0,0,1,2.58-.47,20.185,20.185,0,0,1,2.132.069c.147.013.27.021.376.027a.393.393,0,0,0,.112-.016,8.381,8.381,0,0,1,2.647-.323,18.008,18.008,0,0,1,3.218.238,18.03,18.03,0,0,1,3.218-.238,8.46,8.46,0,0,1,2.647.323.308.308,0,0,0,.112.016c.107-.005.232-.013.376-.027a20.152,20.152,0,0,1,2.132-.069,5.4,5.4,0,0,1,2.583.47.259.259,0,0,0,.176.051,10.069,10.069,0,0,1,2.863.227c.3.163.36.267.379.36a.263.263,0,0,0,.3.195,4.3,4.3,0,0,1,1.46.109.708.708,0,0,1,.342.227.273.273,0,0,0,.232.123.286.286,0,0,0,.131-.032.226.226,0,0,0,.1-.326"
        transform="translate(-151.728 -197.508)"
        fill="#fff"
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M240.293,267.888c-.44-.7-1.564-.7-1.895-.691a2.435,2.435,0,0,0-1.281-.627,8.925,8.925,0,0,0-2.495-.163,6.017,6.017,0,0,0-3.186-.712,10.1,10.1,0,0,0-2.316.117,9.86,9.86,0,0,0-3.632-.382c-.56.027-1.225.083-1.822.149-.446.051-.755.088-.9.12-.144-.035-.456-.069-.9-.12-.6-.069-1.262-.123-1.822-.149a9.935,9.935,0,0,0-3.632.382,9.913,9.913,0,0,0-2.313-.117,6.017,6.017,0,0,0-3.186.712,8.925,8.925,0,0,0-2.495.163,2.448,2.448,0,0,0-1.281.627c-.331-.013-1.457-.008-1.895.691a.178.178,0,0,0,.056.246.172.172,0,0,0,.093.027.178.178,0,0,0,.149-.083c.392-.624,1.644-.52,1.657-.52a.171.171,0,0,0,.16-.072,1.94,1.94,0,0,1,1.131-.566,8.663,8.663,0,0,1,2.447-.149.161.161,0,0,0,.117-.027l.027-.016a6.312,6.312,0,0,1,3.034-.672,9.292,9.292,0,0,1,2.273.12.172.172,0,0,0,.125-.016,9.391,9.391,0,0,1,3.514-.371c1.161.056,2.727.283,2.74.288s1.577-.232,2.74-.288a9.326,9.326,0,0,1,3.514.371.17.17,0,0,0,.125.016,9.14,9.14,0,0,1,2.273-.12,6.3,6.3,0,0,1,3.034.672l.024.016a.168.168,0,0,0,.117.027,8.662,8.662,0,0,1,2.447.149,1.953,1.953,0,0,1,1.131.566.171.171,0,0,0,.16.072c.013,0,1.265-.1,1.657.52a.178.178,0,0,0,.149.083.172.172,0,0,0,.093-.027.176.176,0,0,0,.056-.246"
        transform="translate(-150.458 -194.592)"
        fill="#fff"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M269.1,130.719c-.112-.4-.664-.558-.664-.558l-.019-.117a.349.349,0,0,0,.2-.352.322.322,0,0,0-.2-.339l-.011-.133s.555-.219.555-.6a.611.611,0,0,0-.539-.63v-.131a.415.415,0,0,0,.248-.374.3.3,0,0,0-.171-.275s-.005-.077-.005-.211c.214-.085.326-.16.454-.619s-.32-.83-.443-1a1.114,1.114,0,0,1-.056-.6.486.486,0,0,0,.189-.363.419.419,0,0,0-.176-.339l-.013-.2c.307-.037.5-.245.5-.656s-.478-.507-.478-.507l-.016-.336a.872.872,0,0,0,.67-1.057,1.033,1.033,0,1,0-2.065,0,.872.872,0,0,0,.67,1.057l-.016.336s-.478.1-.478.507.189.619.5.656l-.013.2a.419.419,0,0,0-.176.339.486.486,0,0,0,.189.363,1.114,1.114,0,0,1-.056.6c-.123.171-.571.542-.443,1s.24.534.454.619c0,.133-.005.211-.005.211a.306.306,0,0,0-.171.275.414.414,0,0,0,.248.374v.131a.609.609,0,0,0-.536.63c0,.382.555.6.555.6l-.011.133a.329.329,0,0,0-.2.339.357.357,0,0,0,.2.352l-.019.117s-.552.16-.664.558a1.348,1.348,0,0,0,.109.913,2.691,2.691,0,0,1,.9-.1,2.781,2.781,0,0,1,.9.1,1.345,1.345,0,0,0,.109-.913m-1.139-8.795s-.627-.035-.646-.56a.859.859,0,0,1,.4-.76,5.191,5.191,0,0,1,0,.59c-.024.16-.08.229-.051.344s.155.16.3.184v.205Zm.261,0v-.205c.144-.021.27-.069.3-.184s-.029-.184-.051-.344a5.206,5.206,0,0,1,0-.59.864.864,0,0,1,.4.76c-.016.526-.646.56-.646.56"
        transform="translate(-195.78 -88.185)"
        fill="#fff"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M327.284,279.932a1.422,1.422,0,0,1,.862.395h0v-.947a1.438,1.438,0,0,0-.865-.4,5.953,5.953,0,0,0-.971-.056v.95a5.983,5.983,0,0,1,.971.059"
        transform="translate(-239.239 -204.498)"
        fill="#fff"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M328.156,295.606a1.762,1.762,0,0,0-.715-.235,7.553,7.553,0,0,0-1.121-.1V322.2h1.836V295.606Z"
        transform="translate(-239.246 -216.482)"
        fill="#fff"
      />
      <path
        id="Path_34"
        data-name="Path 34"
        d="M327.436,286.48a1.762,1.762,0,0,1,.715.235l-.008-2.866a1.427,1.427,0,0,0-.865-.395,6.227,6.227,0,0,0-.969-.056v2.983a7.575,7.575,0,0,1,1.126.1"
        transform="translate(-239.239 -207.777)"
        fill="#fff"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M298.408,318.69V290.854a6.427,6.427,0,0,0-1.825-.339,23.2,23.2,0,0,0-2.852.037V318.69Z"
        transform="translate(-215.353 -212.973)"
        fill="#fff"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M296.86,275c1.193.085,1.478.358,1.55.48v-.95c-.069-.123-.355-.395-1.55-.483a16.836,16.836,0,0,0-3.13.069v.953a16.546,16.546,0,0,1,3.13-.072"
        transform="translate(-215.353 -200.887)"
        fill="#fff"
      />
      <path
        id="Path_37"
        data-name="Path 37"
        d="M296.575,281.545a6.424,6.424,0,0,1,1.825.339v-2.86c-.069-.125-.355-.395-1.55-.48a16.834,16.834,0,0,0-3.13.069v2.97a22.7,22.7,0,0,1,2.852-.037"
        transform="translate(-215.345 -204.186)"
        fill="#fff"
      />
      <path
        id="Path_38"
        data-name="Path 38"
        d="M314.92,277.033c.667.072.891.272.963.368v-.95c-.069-.1-.294-.3-.963-.371a20.7,20.7,0,0,0-2.37-.011v.95a21.336,21.336,0,0,1,2.37.011"
        transform="translate(-229.151 -202.383)"
        fill="#fff"
      />
      <path
        id="Path_39"
        data-name="Path 39"
        d="M315.883,320.1V292.778a5.423,5.423,0,0,0-1.513-.318,16.244,16.244,0,0,0-1.82-.048V320.1Z"
        transform="translate(-229.151 -214.382)"
        fill="#fff"
      />
      <path
        id="Path_40"
        data-name="Path 40"
        d="M314.36,283.574a5.4,5.4,0,0,1,1.513.318v-2.946c-.072-.1-.3-.3-.963-.368a20.129,20.129,0,0,0-2.369-.011v2.962a17.61,17.61,0,0,1,1.817.048"
        transform="translate(-229.143 -205.68)"
        fill="#fff"
      />
      <path
        id="Path_41"
        data-name="Path 41"
        d="M218.407,292.459a5.255,5.255,0,0,0-1.537.326V320.1h3.3V292.409a16.579,16.579,0,0,0-1.766.051"
        transform="translate(-159.002 -214.381)"
        fill="#fff"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        d="M218,276.151a1.675,1.675,0,0,0-1.129.544v.95A1.663,1.663,0,0,1,218,277.1a16.937,16.937,0,0,1,2.175-.037v-.95a16.956,16.956,0,0,0-2.175.035"
        transform="translate(-159.002 -202.424)"
        fill="#fff"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        d="M218,280.634a1.675,1.675,0,0,0-1.129.544v2.754a5.2,5.2,0,0,1,1.537-.326,16.6,16.6,0,0,1,1.766-.051V280.6a16.927,16.927,0,0,0-2.175.037"
        transform="translate(-159.002 -205.713)"
        fill="#fff"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M274.4,274.1a8.559,8.559,0,0,1,2.762.371v-.95a8.558,8.558,0,0,0-2.762-.371,12.638,12.638,0,0,0-2.8.243v.95a12.464,12.464,0,0,1,2.8-.243"
        transform="translate(-199.128 -200.263)"
        fill="#fff"
      />
      <path
        id="Path_45"
        data-name="Path 45"
        d="M252.126,289.59a9.846,9.846,0,0,0-2.676.3v28.14h5.585V289.8c-.472-.053-1.876-.2-2.909-.205"
        transform="translate(-182.888 -212.317)"
        fill="#fff"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M252.233,273.149a8.332,8.332,0,0,0-2.783.379v.95a8.256,8.256,0,0,1,2.783-.379,12.693,12.693,0,0,1,2.8.243v-.95a12.469,12.469,0,0,0-2.8-.243"
        transform="translate(-182.888 -200.263)"
        fill="#fff"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M277.166,318.034V289.886a9.886,9.886,0,0,0-2.652-.3c-1.035,0-2.444.152-2.914.205v28.239Z"
        transform="translate(-199.128 -212.317)"
        fill="#fff"
      />
      <path
        id="Path_48"
        data-name="Path 48"
        d="M274.5,280.636a9.837,9.837,0,0,1,2.652.3V278a8.473,8.473,0,0,0-2.762-.371,12.636,12.636,0,0,0-2.8.243v2.97c.47-.053,1.879-.206,2.914-.206"
        transform="translate(-199.12 -203.548)"
        fill="#fff"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M252.233,277.622a8.217,8.217,0,0,0-2.783.382v2.93a9.847,9.847,0,0,1,2.676-.3c1.033,0,2.436.152,2.909.205v-2.973a12.672,12.672,0,0,0-2.8-.243"
        transform="translate(-182.888 -203.54)"
        fill="#fff"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M209.657,283.519a1.456,1.456,0,0,0-.857.371v2.866a1.762,1.762,0,0,1,.715-.235,6.968,6.968,0,0,1,1.07-.1V283.45a6.917,6.917,0,0,0-.929.072"
        transform="translate(-153.085 -207.816)"
        fill="#fff"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        d="M232.5,290.514a6,6,0,0,0-1.846.35v27.828H235.3V290.548a22.663,22.663,0,0,0-2.8-.035"
        transform="translate(-169.105 -212.971)"
        fill="#fff"
      />
      <path
        id="Path_52"
        data-name="Path 52"
        d="M232.206,274.269c-1.51.109-1.556.448-1.556.47v.95s-.008-.358,1.556-.47a19.931,19.931,0,0,1,3.1-.005v-.95a19.938,19.938,0,0,0-3.1.005"
        transform="translate(-169.104 -201.039)"
        fill="#fff"
      />
      <path
        id="Path_53"
        data-name="Path 53"
        d="M232.206,278.745c-1.564.112-1.556.472-1.556.472v2.807a6.112,6.112,0,0,1,1.849-.35,23.769,23.769,0,0,1,2.8.035v-2.97a19.862,19.862,0,0,0-3.1,0"
        transform="translate(-169.104 -204.32)"
        fill="#fff"
      />
      <path
        id="Path_54"
        data-name="Path 54"
        d="M209.657,279.032a1.463,1.463,0,0,0-.857.371v.95a1.476,1.476,0,0,1,.857-.371,6.916,6.916,0,0,1,.929-.072v-.95a7.379,7.379,0,0,0-.929.072"
        transform="translate(-153.085 -204.524)"
        fill="#fff"
      />
      <path
        id="Path_55"
        data-name="Path 55"
        d="M209.505,295.379a1.762,1.762,0,0,0-.715.235v26.593h1.785V295.28a6.968,6.968,0,0,0-1.07.1"
        transform="translate(-153.078 -216.489)"
        fill="#fff"
      />
    </g>
  </svg>
</template>


<script>
export default {
  props: {
    width: String,
    height: String,
  },
};
</script>