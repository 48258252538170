<template>
  <div class="position-relative">
    <div
      class="offcanvas offcanvas-end border-0"
      :class="showProject ? 'show' : ''"
      tabindex="-1"
      :style="{
        visibility: showProject ? 'visible' : 'hidden',
        width: showProject ? '20%' : '0%',
      }"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="container">
        <div class="closeCanvas" @click="showProjectFunction(false)">
          <ArrowLeftSvg width="0.5rem" />
        </div>
        <div class="p-3">
          <div class="d-flex justify-content-start align-items-center">
            <div class="title">
              {{ selectedProject?.proje_adi }}
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <LocationPinSvg width="1rem" height="1rem" />
              <div class="mahalle">
                {{ capitalizeSentence(selectedProject?.mahalle?.mahalle_adi) }}
              </div>
            </div>
            <div
              class="status"
              :style="{
                backgroundColor: `#${selectedProject?.durum?.renk}`,
              }"
            >
              {{ selectedProject?.durum?.adi }}
            </div>
          </div>
        </div>
      </div>
      <div class="categories">
        <div
          class="categoryItem"
          @click="setActiveIndex(0)"
          :style="{
            gap: selectedProject?.proje_aciklama === '' || selectedProject?.galeri?.length === 0 ? '1rem' : '0.5rem',
            color: activeIndex === 0 ? '#fff' : '#205c42',
            backgroundColor: activeIndex === 0 ? '#205c42' : '#fff',
          }"
        >
          <InfoCircleSvg width="1rem" height="1rem" :activeIndex="activeIndex" />
          Özet
        </div>
        <div
          v-if="selectedProject?.proje_aciklama !== ''"
          class="categoryItem"
          @click="setActiveIndex(1)"
          :style="{
            gap: selectedProject?.proje_aciklama === '' || selectedProject?.galeri?.length === 0 ? '1rem' : '0.5rem',
            color: activeIndex === 1 ? '#fff' : '#205c42',
            backgroundColor: activeIndex === 1 ? '#205c42' : '#fff',
          }"
        >
          <DocumentLayoutLeftSvg width="1rem" height="1rem" :activeIndex="activeIndex" />
          Detay
        </div>
        <div
          v-if="selectedProject?.galeri?.length !== 0"
          class="categoryItem"
          @click="setActiveIndex(2)"
          :style="{
            gap: selectedProject?.proje_aciklama === '' || selectedProject?.galeri?.length === 0 ? '1rem' : '0.5rem',
            color: activeIndex === 2 ? '#fff' : '#205c42',
            backgroundColor: activeIndex === 2 ? '#205c42' : '#fff',
          }"
        >
          <GaleryImageSvg width="1rem" height="1rem" :activeIndex="activeIndex" />
          Galeri
        </div>
      </div>
      <div
        v-if="activeIndex === 0"
        :style="{
          margin: '1.5rem 1.5rem 0 1.5rem',
        }"
      >
        <!-- <pre>{{ selectedProject }}</pre> -->
        <div
          :style="{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 0 .5rem 0',
            gap: '1rem',
          }"
        >
          <div class="infoDetailBox">
            <div
              class="infoDetailTitle"
              :style="{
                textDecoration: '2px #ECA90C solid underline',
              }"
            >
              İnşaat Alanı
            </div>
            <div class="infoDetailDesc">-</div>
          </div>
          <div class="infoDetailBox">
            <div
              class="infoDetailTitle"
              :style="{
                textDecoration: '2px #28A746 solid underline',
              }"
            >
              Maliyet
            </div>
            <div class="infoDetailDesc">-</div>
          </div>
        </div>
        <div
          :style="{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: '0 0 .5rem 0',
            gap: '1rem',
          }"
        >
          <div class="infoDetailBox">
            <div
              class="infoDetailTitle"
              :style="{
                textDecoration: '2px #0CA0EC solid underline',
              }"
            >
              İş Başlangıç Tarihi
            </div>
            <div class="infoDetailDesc">-</div>
          </div>
          <div class="infoDetailBox">
            <div
              class="infoDetailTitle"
              :style="{
                textDecoration: '2px #BE1B1B solid underline',
              }"
            >
              İş Bitiş Tarihi
            </div>
            <div class="infoDetailDesc">-</div>
          </div>
        </div>
        <button class="block">
          <RaportSvg width="1.3rem" />
          Rapor Oluştur
        </button>
      </div>
      <div
        v-if="activeIndex === 1 && selectedProject?.proje_aciklama !== ''"
        class="projectInfo"
        v-html="decodeEntities(selectedProject?.proje_aciklama)"
      ></div>
      <div
        class="d-flex justify-content-center align-items-center mt-5"
        v-else-if="activeIndex === 1 && selectedProject?.proje_aciklama === ''"
      >
        Herhangi bir detay girilmemiş.
      </div>
      <SimpleGallery
        v-if="activeIndex === 2 && selectedProject?.galeri?.length !== 0"
        galleryID="my-test-gallery"
        :images="images"
      />

      <div
        class="d-flex justify-content-center align-items-center mt-5"
        v-if="activeIndex === 2 && selectedProject?.galeri?.length === 0"
      >
        BOŞ
      </div>
      <!-- <div class="footer-logo">
        <FooterLogoWhiteSvg height="2.25rem" />
      </div> -->
    </div>
  </div>
</template>

<script>
import ArrowLeftSvg from "@/assets/icons/ArrowLeftSvg.vue"
import LocationPinSvg from "@/assets/icons/LocationPinSvg.vue"
// import FooterLogoWhiteSvg from '@/assets/icons/FooterLogoWhiteSvg.vue'
import InfoCircleSvg from "@/assets/icons/InfoCircleSvg.vue"
import DocumentLayoutLeftSvg from "@/assets/icons/DocumentLayoutLeftSvg.vue"
import GaleryImageSvg from "@/assets/icons/GaleryImageSvg.vue"
import RaportSvg from "@/assets/icons/RaportSvg.vue"
import { capitalizeSentence, decodeEntities } from "@/helpers"
import SimpleGallery from "./SimpleGallery.vue"

export default {
  props: {
    selectedProject: Object,
    images: Array,
    showProject: Boolean,
    showProjectFunction: Function,
  },
  components: {
    ArrowLeftSvg,
    // FooterLogoWhiteSvg,
    LocationPinSvg,
    InfoCircleSvg,
    DocumentLayoutLeftSvg,
    GaleryImageSvg,
    RaportSvg,
    SimpleGallery,
  },
  data() {
    return {
      decodeEntities: decodeEntities,
      capitalizeSentence: capitalizeSentence,
      activeIndex: 0,
      index: null,
    }
  },
  watch: {
    images: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setActiveIndex(0)
      }
    },
  },
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index
    },
    getBackendUrl() {
      return process.env.VUE_APP_BACKEND_URL
    },
  },
}
</script>

<style scoped>
.offcanvas {
  padding-top: 2rem;
}
.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  max-width: 2.5rem;
}
.title {
  font-size: 1.1rem;
  text-decoration: 1px #205c42 solid underline;
  text-underline-offset: 3px;
}
.status {
  display: inline-block;
  color: #fff;
  /* background-color: #28a746; */
  padding: 0.1rem 0.7rem;
  margin: 0.5rem 0;
  border-radius: 15px;
}
.footer-logo {
  position: fixed;
  display: flex;
  align-self: center;
  bottom: 30px;
}
.mahalle {
  font-size: 14px;
  margin-left: 0.2rem;
}
.categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.2rem 0 1.6rem;
}

.categoryItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border: 1px solid #205c42;
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
  user-select: none;
  width: 100%;
}

.projectInfo {
  margin: 1.5rem 1.5rem 5rem 1.5rem;
  overflow-y: scroll;
}

.projectInfo::-webkit-scrollbar {
  display: none;
}

.imagesContainer {
  margin: 1.5rem 1.5rem 5rem 1.5rem;
  overflow-y: scroll;
}

.imagesContainer::-webkit-scrollbar {
  display: none;
}

.imagesItem {
  margin: 0.5rem 0.25rem 0 0.25rem;
  border-radius: 15px;
}
.imagesItem img {
  width: 9.87rem;
  height: 7rem;
  border-radius: 15px;
}
.infoDetailBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.infoDetailTitle {
  font-weight: 500;
  margin: 0 0 0.5rem 0;
  text-underline-offset: 5px;
}

.infoDetailDesc {
  font-size: 0.95rem;
  font-weight: 100;
}

.block {
  border: 1px solid;
  color: #fff;
  background-color: #205c42;
  border-radius: 8px;
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin: 1rem 0 0 0;
}

.block svg {
  width: 0.9rem;
  margin-right: 0.5rem;
  padding-bottom: 0.3rem;
}

@media screen and (min-width: 3800px) {
  .footer-logo {
    bottom: 30px;
  }
  .offcanvas {
    padding-top: 1.9rem;
  }
  .title {
    font-size: 2.1rem;
    text-decoration: 3px #205c42 solid underline;
    text-underline-offset: 5px;
    margin: 0 0 -1rem 1rem;
  }
  .status {
    margin: 2rem 0 0 1rem;
    font-size: 1.4rem;
    border-radius: 25px;
  }
  .footer-logo svg {
    height: 4.8rem;
  }
  .closeCanvas {
    padding: 1.5rem 3rem 1.5rem 2rem;
  }
  .closeCanvas svg {
    width: 0.85rem;
  }
}
</style>
