<template>
  <div class="information">
    <div
      class="infoBox"
      v-for="info in infos"
      :key="info.id"
      :style="{
        borderBottom: '2px ' + info.color + ' solid',
        borderRadius: '5px',
      }"
    >
      <div class="count user-select-none" :style="{ color: info.color }">
        {{ info.count }}
      </div>
      <div class="countInfo user-select-none">{{ info.info }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infos: [
        {
          id: 0,
          count: 123,
          info: "Sahadaki Araç Sayısı",
          color: "#4AAC51",
        },
        {
          id: 1,
          count: 123,
          info: "Sahadaki Personel Sayısı",
          color: "#FF8800",
        },
        {
          id: 2,
          count: 123,
          info: "Devam Eden Proje Sayısı",
          color: "#327DD2",
        },
      ],
    };
  },
};
</script>

<style scoped>
.information {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 2.5rem 1rem;
  margin: 0 0.3rem;
  max-height: 5rem;
}
.count {
  font-weight: bolder;
  font-size: 1.5rem;
}
.countInfo {
  font-size: 0.7rem;
  text-align: center;
  line-height: 0.8rem;
}
@media screen and (min-width: 3800px) {
  .information {
    margin: 1.4rem 0;
  }
  .infoBox {
    padding: 4rem 1.4rem 5rem 1.4rem;
    margin: 0 0.7rem;
  }
  .count {
    font-weight: bolder;
    font-size: 3rem;
  }
  .countInfo {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.6rem;
  }
}
</style>