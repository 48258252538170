<template>
  <div class="logoCard">
    <div
      :class="
        showProject ||
        showVehicle ||
        showUser ||
        showProfile ||
        showVectorCoasters ||
        vectorCoasterNew
          ? 'logo2'
          : 'logo'
      "
    >
      <MapLogoSvg height="90px" />
    </div>
  </div>
</template>

<script>
import MapLogoSvg from '../../../assets/icons/MapLogoSvg.vue'

export default {
  props: {
    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,
    vectorCoasterNew: Boolean,
    showProfile: Boolean,
    showVectorCoasters: Boolean,
  },
  components: {
    MapLogoSvg,
  },
}
</script>

<style scoped>
.logoCard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.logo {
  padding: 2.45rem 1.8rem;
  background-color: #205c42;
}

.logo2 {
  padding: 2.45rem 1.8rem;
  background-color: #205c42;
  margin-left: 2rem;
}

@media only screen and (min-width: 3800px) {
  .logoCard {
    margin-left: 2.2rem;
  }
  .logo {
    padding: 4rem 3rem;
  }
  svg {
    height: 160px;
  }
  .logo2 {
    padding: 4rem 3rem;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1700px) {
  .logoCard {
    margin-left: 2.2rem;
  }
}

@media only screen and (max-width: 1600px) {
  .logoCard {
    margin-left: 2.6rem;
  }
}

@media only screen and (max-width: 1500px) {
  .logoCard {
    margin-left: 3.5rem;
  }
}

@media only screen and (max-width: 1100px) {
  .logoCard {
    margin-left: 4rem;
  }
  .logoCard svg {
    height: 75px;
  }
}

@media only screen and (max-width: 1100px) {
  .logoCard {
    margin-left: 4.3rem;
  }
  .logoCard svg {
    height: 75px;
  }
}
</style>
