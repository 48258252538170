<template>
  <div
    v-if="showFilter"
    :class="
      showProject || showVehicle || showUser || showProfile || showVectorCoasters
        ? 'filters2'
        : 'filters'
    "
  >
    <div
      class="d-flex flex-row justify-content-between align-items-center my-1 mx-2"
    >
      <div class="user-select-none title">Filtre Kategorileri</div>
      <div @click="selectFilter('')">
        <a
          href="#"
          class="pe-auto text-decoration-none text-black user-select-none title2"
        >
          <DeleteRedSvg width="0.75rem" />
          <div class="ms-2 mt-1">Filteryi temizle</div></a
        >
      </div>
    </div>
    <div
      ref="scroll_container"
      class="d-flex flex-row justify-content-start align-items-center mt-2 filterScroll"
      @mousewheel="scrollX"
    >
      <div
        class="filterItem"
        @click="selectFilter('Ada')"
        :style="{
          backgroundColor: selectedFilter === 'Ada' ? '#2892fc' : '#fff',
        }"
      >
        <FilterAdaParselSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{ color: selectedFilter === 'Ada' ? '#fff' : '#1a1a1a' }"
        >
          Ada Parsel
        </div>
      </div>
      <div
        class="filterItem"
        @click="selectFilter('Araç')"
        :style="{
          backgroundColor: selectedFilter === 'Araç' ? '#CB2121' : '#fff',
        }"
      >
        <FilterCarSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{ color: selectedFilter === 'Araç' ? '#fff' : '#1a1a1a' }"
        >
          Araç
        </div>
      </div>
      <div
        class="filterItem"
        @click="selectFilter('Kamera')"
        :style="{
          backgroundColor: selectedFilter === 'Kamera' ? '#17be33' : '#fff',
        }"
      >
        <FilterCameraSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{ color: selectedFilter === 'Kamera' ? '#fff' : '#1a1a1a' }"
        >
          Kamera
        </div>
      </div>
      <div
        class="filterItem"
        @click="selectFilter('Ruhsat')"
        :style="{
          backgroundColor: selectedFilter === 'Ruhsat' ? '#f60' : '#fff',
        }"
      >
        <FilterRuhsatSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{ color: selectedFilter === 'Ruhsat' ? '#fff' : '#1a1a1a' }"
        >
          Ruhsat
        </div>
      </div>
      <div
        class="filterItem"
        @click="selectFilter('Yatırımlar')"
        :style="{
          backgroundColor: selectedFilter === 'Yatırımlar' ? '#205c42' : '#fff',
        }"
      >
        <FilterYatirimlarSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{
            color: selectedFilter === 'Yatırımlar' ? '#fff' : '#1a1a1a',
          }"
        >
          Yatırımlar
        </div>
      </div>
      <div
        class="filterItem"
        @click="selectFilter('Envanter')"
        :style="{
          backgroundColor: selectedFilter === 'Envanter' ? '#6330db' : '#fff',
        }"
      >
        <FilterEnvanterSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{ color: selectedFilter === 'Envanter' ? '#fff' : '#1a1a1a' }"
        >
          Envanter
        </div>
      </div>
      <div
        class="filterItem"
        @click="selectFilter('Talepler')"
        :style="{
          backgroundColor: selectedFilter === 'Talepler' ? '#53dce1' : '#fff',
        }"
      >
        <FilterTalepSvg width="1.3rem" :selectedFilter="selectedFilter" />
        <div
          class="filtersText"
          :style="{ color: selectedFilter === 'Talepler' ? '#fff' : '#1a1a1a' }"
        >
          Talepler
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterAdaParselSvg from "@/assets/icons/FilterAdaParselSvg.vue";
import FilterCarSvg from "@/assets/icons/FilterCarSvg.vue";
import FilterCameraSvg from "@/assets/icons/FilterCameraSvg.vue";
import FilterRuhsatSvg from "@/assets/icons/FilterRuhsatSvg.vue";
import FilterYatirimlarSvg from "@/assets/icons/FilterYatirimlarSvg.vue";
import FilterEnvanterSvg from "@/assets/icons/FilterEnvanterSvg.vue";
import FilterTalepSvg from "@/assets/icons/FilterTalepSvg.vue";
import DeleteRedSvg from "@/assets/icons/DeleteRedSvg.vue";

export default {
  props: {
    showFilterFunction: Function,
    showFilter: Boolean,

    selectFilter: Function,
    selectedFilter: String,

    showProject: Boolean,
    showVehicle: Boolean,
    showUser: Boolean,
    showProfile: Boolean,
    showVectorCoasters: Boolean,
  },
  components: {
    FilterAdaParselSvg,
    FilterCarSvg,
    FilterCameraSvg,
    FilterRuhsatSvg,
    FilterYatirimlarSvg,
    FilterEnvanterSvg,
    FilterTalepSvg,
    DeleteRedSvg,
  },
  methods: {
    scrollX(e) {
      this.$refs["scroll_container"].scrollLeft += e.deltaY;
    },
  },
};
</script>

<style scoped>
.filters {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 0.5rem;
  width: 32rem;
  transition: width 0.3s;
}

.filters2 {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 0.5rem;
  width: 25rem;
  transition: width 0.3s;
}

.filterScroll {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 0.5rem;
  display: inline-block;
  touch-action: unset !important;
}

.filterScroll::-webkit-scrollbar {
  display: none;
}

.filterItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 0.2rem 1rem 0.5rem;
  border: 1px solid #c9c9c9;
  min-width: 5rem;
  min-height: 5rem;
  border-radius: 10px;
}
.filtersText {
  font-size: 0.7rem;
  margin-top: 0.5rem;
  line-height: 1rem;
  user-select: none;
}

.title2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

@media screen and (min-width: 3800px) {
  .filters {
    margin-top: 1rem;
    width: 64.4rem;
  }
  .filters2 {
    margin-top: 1rem;
    width: 50.8rem;
  }
  .filterItem {
    min-width: 8.32rem;
    min-height: 7.6rem;
    border: 1px solid #c9c9c9;
    border-radius: 10px;
  }

  .filterItem svg {
    width: 2rem;
  }
  .filtersText {
    font-size: 1.3rem;
    margin-top: 1rem;
    line-height: 1rem;
    user-select: none;
  }
  .title {
    margin: 0.5rem 0.8rem 0 0.8rem;
    font-size: 1.5rem;
  }
  .title2 {
    font-size: 1.3rem;
  }
  .title2 svg {
    width: 1rem;
  }
}
</style>