<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 30.617 34.664"
  >
    <g id="security-free-material-svgrepo-com" transform="translate(-29.89)">
      <path
        id="Path_216"
        data-name="Path 216"
        d="M45.2,0,29.89,8.838a41.821,41.821,0,0,0,.816,8.494C33.866,32.614,45.2,34.664,45.2,34.664s11.333-2.05,14.492-17.332a41.82,41.82,0,0,0,.816-8.494Zm.008,31.6-.008,0V17.332H34.036a37.168,37.168,0,0,1-.839-6.9L45.2,3.5h0v13.83H56.362C53.975,27.989,47.319,30.925,45.206,31.6Z"
        fill="#a4a4a4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>