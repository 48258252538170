<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 26.407 19.905"
  >
    <g
      id="eye-svgrepo-com_3_"
      data-name="eye-svgrepo-com (3)"
      transform="translate(-1.459 -4)"
    >
      <path
        id="Path_270"
        data-name="Path 270"
        d="M16.674,12.837A3.837,3.837,0,1,1,12.837,9,3.837,3.837,0,0,1,16.674,12.837Z"
        transform="translate(1.825 1.116)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_271"
        data-name="Path 271"
        d="M14.663,5a12.8,12.8,0,0,0-12.2,8.952,12.794,12.794,0,0,0,24.407,0A12.8,12.8,0,0,0,14.663,5Z"
        transform="translate(0)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>