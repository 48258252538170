<template>
  <div :class="name === 'mudurluk' || name === 'yetki' ? 'w-100' : 'w-50'">
    <label class="user-select-none">{{ label }}</label>
    <div class="input-group">
      <span
        class="input-group-text"
        :style="{
          backgroundColor: disabled ? '#ebebeb !important' : '#fff !important',
        }"
      >
        <slot></slot>
      </span>
      <input
        :id="name"
        class="form-control"
        :style="{ borderRight: disabled ? 0 : 1 }"
        :name="name"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="onInputFocus"
        @input="onInputChange"
      />
      <span class="input-group-text2" v-if="disabled">
        <slot name="lock"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    placeholder: String,
    name: String,
    type: String,
    label: String,
    disabled: Boolean,
    checkExist: Function,
  },
  methods: {
    onInputChange(input) {
      this.$emit("onInputChange", input);
    },
    onInputFocus(input) {
      this.$emit("onInputFocus", input);
    },
  },
};
</script>

<style scoped>
label {
  font-size: 0.8rem;
  margin-top: 1rem;
}
.input-group {
  padding-right: 0.5rem !important;
}
.input-group:focus-within span {
  background-color: #fff !important;
}

.form-control {
  border-left: 0;
  font-size: 0.8rem;
}
.form-control::placeholder {
  color: #a4a4a4;
}
.form-control:disabled {
  background-color: #ebebeb;
}

.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.input-group-text {
  background-color: #fff !important;
  padding: 0.8rem 0 0.8rem 0.65rem;
  border-right: 0;
  color: #c8c8c8;
}

.input-group-text2 {
  background-color: #ebebeb !important;
  padding: 0.4rem 0.65rem 0.5rem 0;
  border: 1px solid #dee2e6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #c8c8c8;
}
@media screen and (min-width: 3800px) {
  label {
    font-size: 1.8rem;
    margin-top: 2rem;
  }
  .input-group {
    padding-right: 1rem !important;
  }
  .form-control {
    border-left: 0;
    font-size: 1.6rem;
  }
}
</style>