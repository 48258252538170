<template>
  <svg
    id="katman_1"
    data-name="katman 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 66.82 69.101"
    class="mb-2"
  >
    <g id="Group_107" data-name="Group 107">
      <path
        id="Path_223"
        data-name="Path 223"
        d="M57.033,55.989s-.01-.015-.064-.035h0l-.055-.015h0s-.05-.01-.084-.015a3.1,3.1,0,0,0-.352-.015,2.212,2.212,0,0,0-.352.015h0a.841.841,0,0,0-.139.03h0c-.055.02-.064.035-.064.035L45.75,75.277a2.7,2.7,0,0,1,.615-.06h0l7.687-15.1h.079L46.682,74.989a1.9,1.9,0,0,1,.511-.179,5.929,5.929,0,0,1,1.607-.1l6.5-16.228h.084L49.093,74.558a5.186,5.186,0,0,1,1.78-.312,7.027,7.027,0,0,1,1.423.064l3.9-17.537h.079L52.554,74.265a7,7,0,0,1,2.167-.188c.362.02.794.055,1.18.1.188.02.337.04.446.055l.079-17.259h.094l.064,17.264h0c.109-.015.268-.035.471-.06.387-.045.818-.079,1.18-.1a7.148,7.148,0,0,1,2.133.179l-3.72-17.482h.079l3.9,17.542a6.888,6.888,0,0,1,1.448-.069,5.373,5.373,0,0,1,1.746.3L57.544,58.474h.084L64.12,74.687a.159.159,0,0,1,.025.015,5.813,5.813,0,0,1,1.617.1,2.252,2.252,0,0,1,.446.144l-7.4-14.839h.079l7.618,15.022c.04.03.064.06.089.079a2.683,2.683,0,0,1,.61.06L57.033,55.984Z"
        transform="translate(-23.06 -28.181)"
        fill="#285843"
      />
      <path
        id="Path_224"
        data-name="Path 224"
        d="M30.69,42.506A20.11,20.11,0,0,1,42.985,30.36H30.69Z"
        transform="translate(-15.469 -15.303)"
        fill="#538072"
      />
      <path
        id="Path_225"
        data-name="Path 225"
        d="M91.34,42.506V30.36H79.05A20.1,20.1,0,0,1,91.34,42.506"
        transform="translate(-39.845 -15.303)"
        fill="#538072"
      />
      <path
        id="Path_226"
        data-name="Path 226"
        d="M57.044,23.627V23.31h0V9.785H43.2L33.412,0,23.627,9.785H9.785V22.794h0v.833L0,33.412,9.785,43.2V57.04h13.5V53.32H19.555V34.543h0v-.908s1.736-11.7,13.916-14.03h.035c12.171,2.336,13.678,14.03,13.678,14.03v.922h0V53.32H43.55v3.72H57.035V43.584h0V43.2l9.785-9.785-9.785-9.785ZM33.412,5.262l4.523,4.523H28.884l4.528-4.528ZM9.785,28.889v9.046L5.262,33.412ZM53.32,53.32H51.51V36.606c0-18.638-17.433-21.162-18.122-21.257h-.03c-.689.094-18.122,2.619-18.122,21.257V53.32H13.5V13.5H53.32Zm3.72-24.431,4.523,4.523-4.523,4.523Z"
        fill="#285843"
      />
      <path
        id="Path_227"
        data-name="Path 227"
        d="M66.845,137.95H44.492a.342.342,0,0,0,0,.684H66.845a.342.342,0,0,0,0-.684"
        transform="translate(-22.254 -69.533)"
        fill="#285843"
      />
      <path
        id="Path_228"
        data-name="Path 228"
        d="M67.173,95.217a.833.833,0,0,0-.407-.288,3.169,3.169,0,0,0-.972-.1.844.844,0,0,0-.357-.3,6.353,6.353,0,0,0-1.984-.193A4.155,4.155,0,0,0,61.628,94c-.7-.01-1.136.025-1.418.045l-.193.015a5.951,5.951,0,0,0-1.8-.218,11.862,11.862,0,0,0-2.088.149,11.862,11.862,0,0,0-2.088-.149,6.052,6.052,0,0,0-1.8.218c-.06,0-.119-.01-.193-.015A12.021,12.021,0,0,0,50.633,94a4.155,4.155,0,0,0-1.825.337,6.064,6.064,0,0,0-1.984.193.88.88,0,0,0-.357.3,3.169,3.169,0,0,0-.972.1.792.792,0,0,0-.407.288.15.15,0,0,0,.064.213.163.163,0,0,0,.084.02.175.175,0,0,0,.149-.079.455.455,0,0,1,.218-.149,2.784,2.784,0,0,1,.947-.069.171.171,0,0,0,.2-.124c.01-.06.05-.129.243-.233a6.561,6.561,0,0,1,1.855-.144.183.183,0,0,0,.114-.035,3.544,3.544,0,0,1,1.671-.3c.689-.01,1.106.025,1.379.045.094.01.174.015.243.02a.158.158,0,0,0,.069-.01,5.472,5.472,0,0,1,1.716-.208,11.559,11.559,0,0,1,2.083.154,11.559,11.559,0,0,1,2.083-.154,5.627,5.627,0,0,1,1.716.208.193.193,0,0,0,.074.01c.069,0,.149-.01.243-.02.278-.02.689-.055,1.379-.045a3.544,3.544,0,0,1,1.671.3.193.193,0,0,0,.114.035,6.583,6.583,0,0,1,1.855.144c.193.1.233.174.243.233a.168.168,0,0,0,.2.124,2.794,2.794,0,0,1,.947.069.424.424,0,0,1,.218.149.175.175,0,0,0,.149.079.163.163,0,0,0,.084-.02.147.147,0,0,0,.064-.213"
        transform="translate(-22.715 -47.298)"
        fill="#538072"
      />
      <path
        id="Path_229"
        data-name="Path 229"
        d="M67.167,94.061c-.283-.451-1.012-.456-1.23-.446a1.547,1.547,0,0,0-.828-.407,5.814,5.814,0,0,0-1.617-.1,3.857,3.857,0,0,0-2.063-.461,6.527,6.527,0,0,0-1.5.074,6.342,6.342,0,0,0-2.351-.248c-.362.02-.794.055-1.18.1-.288.035-.491.055-.58.079-.094-.02-.293-.045-.58-.079-.387-.045-.818-.079-1.18-.1a6.342,6.342,0,0,0-2.351.248,6.223,6.223,0,0,0-1.5-.074,3.857,3.857,0,0,0-2.063.461,5.814,5.814,0,0,0-1.617.1,1.547,1.547,0,0,0-.828.407c-.213-.01-.942,0-1.23.446a.118.118,0,0,0,.035.159.141.141,0,0,0,.06.02.121.121,0,0,0,.1-.055c.253-.407,1.066-.337,1.071-.337a.125.125,0,0,0,.1-.05,1.291,1.291,0,0,1,.734-.367,5.3,5.3,0,0,1,1.582-.094.1.1,0,0,0,.074-.02l.015-.01a4.088,4.088,0,0,1,1.964-.436,6.079,6.079,0,0,1,1.473.079.141.141,0,0,0,.079-.01,5.982,5.982,0,0,1,2.276-.238c.754.035,1.766.183,1.776.188s1.022-.149,1.776-.188a6.124,6.124,0,0,1,2.276.238.112.112,0,0,0,.079.01,6.043,6.043,0,0,1,1.473-.079,4.088,4.088,0,0,1,1.964.436l.015.01a.293.293,0,0,0,.074.02,5.542,5.542,0,0,1,1.587.094,1.28,1.28,0,0,1,.734.367.125.125,0,0,0,.1.05c.01,0,.818-.069,1.071.337a.114.114,0,0,0,.1.055.1.1,0,0,0,.06-.02.113.113,0,0,0,.035-.159"
        transform="translate(-22.407 -46.603)"
        fill="#538072"
      />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M67.345,48.655c-.074-.258-.431-.362-.431-.362l-.01-.074a.226.226,0,0,0,.129-.228.206.206,0,0,0-.129-.218v-.089s.352-.144.352-.392a.392.392,0,0,0-.347-.407V46.8a.264.264,0,0,0,.159-.243.192.192,0,0,0-.109-.179V46.24c.139-.055.213-.1.293-.4s-.208-.536-.288-.65a.722.722,0,0,1-.035-.392.316.316,0,0,0,.124-.233.274.274,0,0,0-.114-.218l-.01-.129c.2-.025.322-.159.322-.427s-.307-.327-.307-.327l-.01-.218a.569.569,0,0,0,.436-.684.833.833,0,0,0-.67-.67h0a.838.838,0,0,0-.67.67.566.566,0,0,0,.436.684l-.01.218s-.307.064-.307.327.124.4.322.427l-.01.129a.264.264,0,0,0-.114.218.3.3,0,0,0,.124.233.723.723,0,0,1-.035.392c-.079.109-.372.352-.288.65s.154.347.293.4v.139a.2.2,0,0,0-.109.179.27.27,0,0,0,.159.243v.084a.392.392,0,0,0-.347.407c0,.248.362.392.362.392v.089a.214.214,0,0,0-.139.218.233.233,0,0,0,.129.228l-.01.074s-.357.1-.431.362a.861.861,0,0,0,.069.59,1.849,1.849,0,0,1,.58-.069h0a1.849,1.849,0,0,1,.58.069.862.862,0,0,0,.069-.59m-.739-5.694s-.407-.02-.417-.362a.554.554,0,0,1,.258-.491,3.132,3.132,0,0,1,0,.382c-.015.1-.055.149-.035.223s.1.1.193.119v.134Zm.169,0v-.134c.094-.015.174-.045.193-.119s-.02-.119-.035-.223a3.134,3.134,0,0,1,0-.382.552.552,0,0,1,.258.491c-.01.342-.417.362-.417.362"
        transform="translate(-33.278 -21.115)"
        fill="#285843"
      />
      <path
        id="Path_231"
        data-name="Path 231"
        d="M87.27,97.813a.961.961,0,0,1,.56.253h0v-.615a.951.951,0,0,0-.56-.258,3.66,3.66,0,0,0-.63-.035v.615a3.718,3.718,0,0,1,.63.04"
        transform="translate(-43.671 -48.972)"
        fill="#285843"
      />
      <path
        id="Path_232"
        data-name="Path 232"
        d="M87.835,103.078a1.128,1.128,0,0,0-.461-.154,4.834,4.834,0,0,0-.724-.064V120.3h1.19V103.078Z"
        transform="translate(-43.676 -51.846)"
        fill="#285843"
      />
      <path
        id="Path_233"
        data-name="Path 233"
        d="M87.369,100.722a1.128,1.128,0,0,1,.461.154V99.021a.979.979,0,0,0-.565-.258,3.63,3.63,0,0,0-.625-.035v1.929a4.357,4.357,0,0,1,.729.064"
        transform="translate(-43.671 -49.763)"
        fill="#538072"
      />
      <path
        id="Path_234"
        data-name="Path 234"
        d="M78.325,119.45V101.427a4.216,4.216,0,0,0-1.18-.218,14.284,14.284,0,0,0-1.845.025V119.45h3.03Z"
        transform="translate(-37.955 -51.003)"
        fill="#285843"
      />
      <path
        id="Path_235"
        data-name="Path 235"
        d="M77.318,96.1c.774.055.957.233,1,.312v-.615c-.045-.079-.228-.258-1-.312a11.22,11.22,0,0,0-2.028.045v.615a10.757,10.757,0,0,1,2.028-.045"
        transform="translate(-37.95 -48.114)"
        fill="#285843"
      />
      <path
        id="Path_236"
        data-name="Path 236"
        d="M77.14,98.981a4.164,4.164,0,0,1,1.18.218V97.345c-.045-.079-.228-.258-1-.312a11.22,11.22,0,0,0-2.028.045V99a14.318,14.318,0,0,1,1.845-.025"
        transform="translate(-37.95 -48.895)"
        fill="#538072"
      />
      <path
        id="Path_237"
        data-name="Path 237"
        d="M83.383,96.8c.431.045.575.174.625.238v-.615c-.045-.064-.188-.193-.625-.238a13.193,13.193,0,0,0-1.533,0v.615a14.386,14.386,0,0,1,1.533,0"
        transform="translate(-41.256 -48.469)"
        fill="#285843"
      />
      <path
        id="Path_238"
        data-name="Path 238"
        d="M84,119.788V102.1a3.4,3.4,0,0,0-.982-.2,10.225,10.225,0,0,0-1.18-.03v17.929H84Z"
        transform="translate(-41.251 -51.342)"
        fill="#285843"
      />
      <path
        id="Path_239"
        data-name="Path 239"
        d="M83.025,99.688a3.884,3.884,0,0,1,.982.2V97.982c-.045-.064-.193-.193-.625-.238a13.191,13.191,0,0,0-1.533,0v1.919a11.385,11.385,0,0,1,1.175.03"
        transform="translate(-41.256 -49.255)"
        fill="#538072"
      />
      <path
        id="Path_240"
        data-name="Path 240"
        d="M49.507,101.892a3.478,3.478,0,0,0-1,.208v17.686h2.138V101.858a10.926,10.926,0,0,0-1.146.035"
        transform="translate(-24.451 -51.34)"
        fill="#285843"
      />
      <path
        id="Path_241"
        data-name="Path 241"
        d="M49.249,96.215a1.079,1.079,0,0,0-.729.352v.615a1.079,1.079,0,0,1,.729-.352,10.819,10.819,0,0,1,1.409-.025V96.19a10.818,10.818,0,0,0-1.409.025"
        transform="translate(-24.456 -48.479)"
        fill="#285843"
      />
      <path
        id="Path_242"
        data-name="Path 242"
        d="M49.249,97.775a1.1,1.1,0,0,0-.729.352v1.78a3.479,3.479,0,0,1,1-.208,10.922,10.922,0,0,1,1.146-.035V97.75a10.818,10.818,0,0,0-1.409.025"
        transform="translate(-24.456 -49.265)"
        fill="#538072"
      />
      <path
        id="Path_243"
        data-name="Path 243"
        d="M69.4,95.765a5.4,5.4,0,0,1,1.79.243v-.615a5.523,5.523,0,0,0-1.79-.243,7.972,7.972,0,0,0-1.815.159v.615a7.974,7.974,0,0,1,1.815-.159"
        transform="translate(-34.064 -47.96)"
        fill="#285843"
      />
      <path
        id="Path_244"
        data-name="Path 244"
        d="M61.6,100.88a6.279,6.279,0,0,0-1.731.2V119.3h3.615V101.014c-.307-.035-1.215-.134-1.885-.134"
        transform="translate(-30.177 -50.848)"
        fill="#285843"
      />
      <path
        id="Path_245"
        data-name="Path 245"
        d="M61.67,95.15a5.279,5.279,0,0,0-1.8.248v.615a5.422,5.422,0,0,1,1.8-.248,8.013,8.013,0,0,1,1.815.159v-.615a8.011,8.011,0,0,0-1.815-.159"
        transform="translate(-30.177 -47.96)"
        fill="#285843"
      />
      <path
        id="Path_246"
        data-name="Path 246"
        d="M71.191,119.29V101.063a6.541,6.541,0,0,0-1.716-.193c-.67,0-1.582.1-1.885.134V119.29H71.2Z"
        transform="translate(-34.069 -50.843)"
        fill="#285843"
      />
      <path
        id="Path_247"
        data-name="Path 247"
        d="M69.47,98.664a6.54,6.54,0,0,1,1.716.193v-1.9a5.44,5.44,0,0,0-1.79-.238,7.974,7.974,0,0,0-1.815.159V98.8c.3-.035,1.215-.134,1.885-.134"
        transform="translate(-34.064 -48.751)"
        fill="#538072"
      />
      <path
        id="Path_248"
        data-name="Path 248"
        d="M61.67,96.71a5.279,5.279,0,0,0-1.8.248v1.9a6.279,6.279,0,0,1,1.731-.2c.67,0,1.577.1,1.885.134V96.869a8.013,8.013,0,0,0-1.815-.159"
        transform="translate(-30.177 -48.746)"
        fill="#538072"
      />
      <path
        id="Path_249"
        data-name="Path 249"
        d="M46.255,98.78a.951.951,0,0,0-.555.238v1.855a1.128,1.128,0,0,1,.461-.154,4.307,4.307,0,0,1,.694-.064V98.73a4.629,4.629,0,0,0-.6.045"
        transform="translate(-23.035 -49.765)"
        fill="#538072"
      />
      <path
        id="Path_250"
        data-name="Path 250"
        d="M54.515,101.211a3.812,3.812,0,0,0-1.2.228v18.018h3.01V101.235a14.9,14.9,0,0,0-1.815-.025"
        transform="translate(-26.876 -51.005)"
        fill="#285843"
      />
      <path
        id="Path_251"
        data-name="Path 251"
        d="M54.327,95.562c-.977.069-1.007.288-1.007.3v.615s0-.233,1.007-.3a12.532,12.532,0,0,1,2,0v-.615a13.158,13.158,0,0,0-2,0"
        transform="translate(-26.876 -48.149)"
        fill="#285843"
      />
      <path
        id="Path_252"
        data-name="Path 252"
        d="M54.327,97.122c-1.012.074-1.007.307-1.007.307v1.815a3.934,3.934,0,0,1,1.2-.223,14.9,14.9,0,0,1,1.815.025V97.122a13.159,13.159,0,0,0-2,0"
        transform="translate(-26.876 -48.935)"
        fill="#538072"
      />
      <path
        id="Path_253"
        data-name="Path 253"
        d="M46.255,97.23a.934.934,0,0,0-.555.243v.615a.951.951,0,0,1,.555-.238,3.935,3.935,0,0,1,.6-.045V97.19a4.631,4.631,0,0,0-.6.045"
        transform="translate(-23.035 -48.988)"
        fill="#285843"
      />
      <path
        id="Path_254"
        data-name="Path 254"
        d="M46.171,102.93a1.128,1.128,0,0,0-.461.154V120.3h1.156V102.87a4.361,4.361,0,0,0-.694.064"
        transform="translate(-23.04 -51.851)"
        fill="#285843"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>