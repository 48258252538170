<template>
  <div class="user user-select-none">
    <div class="profilPhoto">
      <div class="photoWrapper">
        <img src="../../../../assets/png/defaultProfile.png" alt="" />
      </div>
      <div class="userInfo">
        <div class="userName">{{ this.$store.state.auth.name }}</div>
        <div class="userPhoneNumber">{{ this.$store.state.auth.surname }}</div>
        <div class="userFakeName">
          {{ this.$store.state.auth.authorization_level }}
        </div>
        <div @click="logout" class="exit">Çıkış Yap</div>
      </div>
    </div>
    <div class="closeCanvas" @click="showUserFunction(false)">X</div>
  </div>
  <!-- <div class="d-flex justify-content-center align-items-center faaliyetbuttton">
    <button class="block">
      <AddWorkSvg width="1.3rem" />
      Yeni Faaliyet Ekle
    </button>
  </div> -->
</template>

<script>
// import AddWorkSvg from "@/assets/icons/AddWorkSvg.vue";

export default {
  props: {
    showUserFunction: Function,
  },
  components: {
    // AddWorkSvg,
  },
  methods: {
    logout() {
      this.showUserFunction(false);
      this.$store.dispatch("setLogout");
      localStorage.removeItem("data");
    },
  },
};
</script>

<style scoped>
.user {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-right: 0.5rem;
  margin-left: -0.5rem;
}
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 1rem;
}
.userName {
  font-size: 1.2rem;
}
.userPhoneNumber {
  margin-top: -5px;
  font-size: 0.75rem;
}
.userFakeName {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  color: #fff;
  background-color: #2aa83f;
  font-size: 0.7rem;
  width: 85%;
}
.exit {
  color: #c1c1c1;
  margin-top: 5px;
  font-size: 0.7rem;
  cursor: pointer;
}
.closeCanvas {
  background-color: #f8f8f8;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  margin: 0 0 2.7rem 0;
}
.profilPhoto {
  display: flex;
  padding: 1rem;
}
.photoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  border: 2px #f8f8f8 solid;
  border-radius: 50%;
}
.profilPhoto img {
  max-width: 4.4rem;
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #205c42;
  color: #fff;
  border-radius: 5px;
  padding: 0.7rem 0;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin: 0.2rem 0.5rem;
}

@media screen and (min-width: 3800px) {
  .user {
    margin-right: 1.5rem;
    margin-left: 0.5rem;
  }
  .userInfo {
    margin-left: 1.5rem;
  }
  .photoWrapper {
    width: 9rem !important;
    height: 9rem !important;
  }
  .profilPhoto img {
    max-width: 8.3rem !important;
  }
  .userName {
    font-size: 1.9rem;
  }
  .userPhoneNumber {
    font-size: 1.3rem;
  }
  .userFakeName {
    font-size: 1.15rem;
  }
  .exit {
    font-size: 1.25rem;
  }
  .closeCanvas {
    padding: 1.35rem 1.8rem;
    margin: 0 0 5rem 0;
  }
  .profilPhoto {
    display: flex;
    padding: 1rem;
  }
  .photoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background-color: #fff;
    border: 2px #d5d5d5 solid;
    border-radius: 50%;
  }
  .profilPhoto img {
    max-width: 4.2rem;
  }
  .block {
    padding: 1.1rem 0;
    font-size: 26px;
    margin: 0.5rem 0.8rem;
  }

  .block svg {
    width: 1.7rem;
  }
}
</style>
