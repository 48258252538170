<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    viewBox="0 0 35.146 31.714"
  >
    <g id="business-bag-svgrepo-com" transform="translate(0 -29.884)">
      <g id="Group_94" data-name="Group 94" transform="translate(0 29.884)">
        <path
          id="Path_203"
          data-name="Path 203"
          d="M20.369,327.519a.8.8,0,0,1-.8.8H15.576a.8.8,0,0,1-.8-.8v-2.806L0,321.356v13.378a1.6,1.6,0,0,0,1.6,1.6H33.549a1.6,1.6,0,0,0,1.6-1.6V321.37l-14.777,3.346Z"
          transform="translate(0 -304.617)"
          fill="#a4a4a4"
        />
        <path
          id="Path_204"
          data-name="Path 204"
          d="M33.549,164.844H1.6a1.6,1.6,0,0,0-1.6,1.6v5.343l14.777,3.357v-1.536a.8.8,0,0,1,.8-.8H19.57a.8.8,0,0,1,.8.8v1.538L35.146,171.8v-5.357A1.6,1.6,0,0,0,33.549,164.844Z"
          transform="translate(0 -157.093)"
          fill="#a4a4a4"
        />
        <path
          id="Path_205"
          data-name="Path 205"
          d="M167.828,33.927a2.167,2.167,0,0,1,2.164-2.164h8a2.167,2.167,0,0,1,2.164,2.164v2.11h1.878v-2.11a4.047,4.047,0,0,0-4.043-4.043h-8a4.047,4.047,0,0,0-4.043,4.043v2.11h1.879v-2.11Z"
          transform="translate(-156.419 -29.884)"
          fill="#a4a4a4"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: String,
  },
};
</script>