import { axiosInstance } from "@/helpers"
import axios from "axios"

const getModule = {
  state: {
    data: [],
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
  },
  actions: {
    setData({ commit, state }) {
      if (state.data.length == 0) {
        axiosInstance()
          .get(process.env.VUE_APP_BACKEND_URL + "/status/project-latest-status", {
            headers: {
              Authorization: this.state.auth.token,
            },
          })
          .then((res) => {
            commit("setData", res.data.data)
          })
      }
    },
  },
  getters: {
    allStatus: (state) => state.data,
  },
}

export default getModule
