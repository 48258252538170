<template>
  <button
    type="button"
    class="d-flex align-items-center justify-content-start btn"
    :style="{
      ...style,
      fontSize: '12.5px',
      width: '100%',
    }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    style: Object,
  },
};
</script>

<style scoped>
button {
  padding: 0.2rem 0.75rem;
}
</style>
