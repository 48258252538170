<template>
  <Transition name="slide-fade">
    <div
      class="d-flex flex-row align-items-end justify-content-between"
      v-if="katmanlar"
    >
      <BuyukSehirButton
        :bsKatmanlar="bsKatmanlar"
        :openBsKatmalar="openBsKatmalar"
      />
      <KaratayKatmanlar
        :krtyKatmanlar="krtyKatmanlar"
        :openKrtyKatmanlar="openKrtyKatmanlar"
        :getMahaleler="getMahaleler"
      />
      <UserKatmanlar
        :userKatmanlar="userKatmanlar"
        :openUserKatmanlar="openUserKatmanlar"
      />
    </div>
  </Transition>
</template>

<script>
import UserKatmanlar from "./UserKatmanlar.vue";
import BuyukSehirButton from "./BuyukSehirButton.vue";
import KaratayKatmanlar from "./KaratayKatmanlar.vue";

export default {
  props: {
    katmanlar: Boolean,
    bsKatmanlar: Boolean,
    userKatmanlar: Boolean,
    krtyKatmanlar: Boolean,
    openBsKatmalar: Function,
    openUserKatmanlar: Function,
    openKrtyKatmanlar: Function,
    getMahaleler: Function,
  },
  components: {
    BuyukSehirButton,
    KaratayKatmanlar,
    UserKatmanlar,
  },
};
</script>

<style scoped>
</style>