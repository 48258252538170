<template>
  <div class="d-flex flex-column">
    <Transition name="slide-fade">
      <div class="d-flex flex-column buttonscontainer" v-if="krtyKatmanlar">
        <button
          @click="getMahaleler"
          type="button"
          class="karatay-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.karatay_mahalleler.type === 'karatay-mahalleler'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <KaratayButtonSvg width="2rem" />
          Mahalleler
        </button>
        <button
          @click="
            $store.dispatch('setKaratayAdaParsel', {
              url: 'https://cbs.karatay.bel.tr/geoserver/KaratayWS/wms',
              type: 'KaratayWS:kbs_para',
            })
          "
          type="button"
          class="karatay-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.karatay_ada_parsel.type === 'KaratayWS:kbs_para'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <KaratayButtonSvg width="2rem" />
          Ada Parsel
        </button>
        <button
          @click="
            $store.dispatch('setKaratayRuhsatli', {
              url: 'https://cbs.karatay.bel.tr/geoserver/KaratayWS/wms',
              type: 'KaratayWS:kbs_yapa_ruhsatli',
            })
          "
          type="button"
          class="karatay-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.karatay_ruhsatli.type ===
                  'KaratayWS:kbs_yapa_ruhsatli'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <KaratayButtonSvg width="2rem" />
          Ruhsatlı
        </button>
        <button
          @click="
            $store.dispatch('setKaratayRuhsatsiz', {
              url: 'https://cbs.karatay.bel.tr/geoserver/KaratayWS/wms',
              type: 'KaratayWS:kbs_yapa_ruhsatsiz',
            })
          "
          type="button"
          class="karatay-katmanlar-test d-flex flex-column align-items-center justify-content-between btn btn-light"
        >
          <div class="selectedDiv">
            <div
              class="selected"
              :style="{
                backgroundColor:
                  $store.state.karatay_ruhsatsiz.type ===
                  'KaratayWS:kbs_yapa_ruhsatsiz'
                    ? '#5B5B5B'
                    : '#fff',
              }"
            ></div>
          </div>
          <KaratayButtonSvg width="2rem" />
          Ruhsatsız
        </button>
      </div>
    </Transition>
    <button
      @click="openKrtyKatmanlar"
      type="button"
      class="karatay-katmanlar d-flex flex-column align-items-center justify-content-between btn btn-light"
    >
      <KaratayButtonSvg width="2rem" />
      Karatay
      <span>Katmanları</span>
    </button>
  </div>
</template>

<script>
import KaratayButtonSvg from "../../../assets/icons/KaratayButtonSvg.vue";

export default {
  props: {
    krtyKatmanlar: Boolean,
    openKrtyKatmanlar: Function,
    getMahaleler: Function,
  },
  components: {
    KaratayButtonSvg,
  },
};
</script>

<style scoped>
.buttonscontainer {
  max-height: 28.9rem;
  overflow-y: scroll;
  overflow-x: none;
}

.selectedDiv {
  position: absolute;
  right: 10px;
  top: 5px;
  border-radius: 50%;
  border: 1px solid #707070;
  width: 15px;
  height: 15px;
}

.selected {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
}

.buttonscontainer::-webkit-scrollbar {
  display: none;
  scroll-padding-right: -100px;
}

.karatay-katmanlar {
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #205c42;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
}

.karatay-katmanlar:hover {
  border-bottom-color: #205c42;
}

.karatay-katmanlar-test {
  position: relative;
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  border-bottom-width: 0.3rem;
  border-bottom-color: #205c42;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
}

.karatay-katmanlar-test:hover {
  border-bottom-color: #205c42;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(580px);
  opacity: 0;
}

@media only screen and (min-width: 3800px) {
  .buttonscontainer {
    max-height: 39rem;
    overflow-y: scroll;
    overflow-x: none;
  }
  .karatay-katmanlar {
    padding: 1rem 2rem;
  }
  .karatay-katmanlar svg {
    width: 2.95rem;
  }

  .karatay-katmanlar-test {
    padding: 1rem 2rem;
  }
  .karatay-katmanlar-test svg {
    width: 2.9rem;
  }
}
</style>